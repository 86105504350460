import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const PreviewBox = ({ title, children, ...props }) => {
  return (
    <Box
      px={{ xs: 1, sm: 2 }}
      py={1}
      sx={{
        borderRadius: { xs: 0, sm: '4px' },
        border: '1px solid rgba(0, 0, 0, 0.12)',
        background: 'rgb(241 241 241 / 35%)',
      }}
      {...props}
    >
      <Grid container alignItems={'flex-start'} spacing={2} rowSpacing={1}>
        {title && (
          <Grid item xs={12}>
            <Typography variant='h6'>{title}</Typography>
          </Grid>
        )}
        {children}
      </Grid>
    </Box>
  );
};
