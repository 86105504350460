import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, FormControl, TextField } from '@mui/material';
import DetalleCoberturaOrdenData from './components/DetalleCoberturaOrdenData';

const VerificarCostoOrdenView = ({ datosOrden, open, onClose, ...props }) => {
  return (
    <div>
      <Dialog
        maxWidth='lg'
        open={open}
        onClose={(e) => {
          if (onClose) {
            onClose(e);
          }
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Verificar Costo Orden de Prestación'}</DialogTitle>
        <DialogContent>
          {datosOrden?.practicas && (
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='practica'
                multiline
                variant='standard'
                label='Práctica'
                defaultValue={
                  datosOrden?.practicas &&
                  Object.values(datosOrden?.practicas).map(
                    (practica) => `(${practica?.codigoPractica}) ${practica?.descripcionPractica}`,
                  )
                }
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          )}
          {datosOrden?.totales && (
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <TextField
                id='coseguro'
                variant='standard'
                label='Coseguro a pagar al Prestador'
                defaultValue={`$ ${datosOrden?.totales?.totalPagar}`}
                size='small'
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { fontWeight: 'bold' },
                }}
              />
            </FormControl>
          )}
          {datosOrden?.practicas && (
            <DetalleCoberturaOrdenData detallePracticas={datosOrden?.practicas} />
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            onClick={(e) => {
              if (onClose) {
                onClose(e);
              }
            }}
            variant='contained'
            color='neutral'
            autoFocus
          >
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VerificarCostoOrdenView;
