import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Chip,
  Container,
  Drawer,
  List,
  MenuList,
  Tooltip,
} from '@mui/material';
import {
  BottomMenuList,
  SideMenuList,
  TopMenuList,
  SideMenuListNoProfesional,
  SideMenuListProfesional,
  BottomMenuListAyuda,
  SideMenuListOptica,
  SideMenuListOdontologos,
  SideMenuListProfesionalBioquimico,
} from '../../routes/Menu';
import { ButtonMenu } from './components/ButtonMenu';
import { Link } from 'react-router-dom';
import { MenuListDrawer } from './components/MenuListDrawer';
import { useUser } from '../../components/Auth/User';
import ButtonDownloadPWA from '../../@iosper/components/PWA/ButtonDownloadPWA';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import Loader from '../Loader/Loader';
import logo from '../../assets/images/logo-small.png';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import TopMenu from './TopMenu';
import Typography from '@mui/material/Typography';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Code, InfoRounded } from '@mui/icons-material';
import { ButtonDownloadPWAiOS } from '../../@iosper/components/PWA/ButtonDownloadPWAiOS';
import { useVersionControlStore } from '../../@iosper/components/VersionControl/store/hooks';
import { getPrestadorLogueado } from '../../services/Busquedas/Prestadores';

const drawerWidth = 290;

const Menu = (props) => {
  const { window } = props;
  const { user } = useUser();
  const { state } = useVersionControlStore();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [esProfesional, setProfesional] = useState(false);
  const [esOptica, setEsOptica] = useState(false);
  const [esOdontologo, setEsOdontologo] = useState(false);
  const [esProfesionalBioquimico, setEsProfesionalBioquimico] = useState(false);

  useEffect(() => {
    if (user) {
      const isProfesional = user.roles[2]?.codigo === '2';
      setProfesional(isProfesional);
      setEsOptica(user?.letraEstablecimiento?.toLowerCase() === 'y');
      setEsOdontologo(user?.letraProfesional?.toLowerCase() === 'd');

      if (isProfesional) {
        const getPrestador = async () => {
          const prestador = await getPrestadorLogueado();
          if (prestador != null) {
            setEsProfesionalBioquimico(Number(prestador?.tipo) === 9);
          }
        };

        getPrestador();
      }
    }
  }, [user]);

  const colorVersion = () => {
    return state.isUptodate ? '#00615C' : '#B12823';
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!user) {
    return (
      <Box sx={{ display: 'flex' }}>
        <AppBar
          position='fixed'
          sx={{
            ml: { md: `${drawerWidth}px` },
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Loader id='headerLoader' />
        </AppBar>
        <Box
          component='main'
          sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          {props.children}
        </Box>
      </Box>
    );
  }

  const drawer = (
    <div>
      <Toolbar />
      <Divider />

      <MenuList id='nav' aria-label='Menú lateral de navegación del sitio Mi-iosper.' tabIndex='0'>
        {!esOptica && !esOdontologo && (
          <MenuListDrawer list={SideMenuList} onMobileOpen={(e) => setMobileOpen(e)} />
        )}
        {esOptica && (
          <MenuListDrawer list={SideMenuListOptica} onMobileOpen={(e) => setMobileOpen(e)} />
        )}
        {esOdontologo && (
          <MenuListDrawer list={SideMenuListOdontologos} onMobileOpen={(e) => setMobileOpen(e)} />
        )}
        {((esProfesional && !esProfesionalBioquimico) || esOdontologo) && (
          <MenuListDrawer list={SideMenuListProfesional} onMobileOpen={(e) => setMobileOpen(e)} />
        )}
        {esProfesionalBioquimico && (
          <MenuListDrawer
            list={SideMenuListProfesionalBioquimico}
            onMobileOpen={(e) => setMobileOpen(e)}
          />
        )}
        {!esProfesional && (
          <MenuListDrawer list={SideMenuListNoProfesional} onMobileOpen={(e) => setMobileOpen(e)} />
        )}
        <MenuListDrawer list={BottomMenuListAyuda} onMobileOpen={(e) => setMobileOpen(e)} />
        <Divider />
        <MenuListDrawer list={BottomMenuList} onMobileOpen={(e) => setMobileOpen(e)} />
      </MenuList>
      <Box
        sx={{ width: '100%', display: 'flex', alignItems: 'flex-end', marginTop: { md: '20px' } }}
      >
        <List sx={{ width: '100%' }}>
          <ButtonDownloadPWA>
            <ButtonMenu
              icon={<InstallDesktopIcon />}
              text='Instalar Aplicación'
              iconColor='#005A55'
              textColor='#005A55'
            />
          </ButtonDownloadPWA>
          <ButtonDownloadPWAiOS>
            <ButtonMenu
              icon={<InfoRounded />}
              text='¿Cómo instalar mi.consultorio?'
              iconColor='#005A55'
              textColor='#005A55'
            />
          </ButtonDownloadPWAiOS>
          <ButtonMenu
            icon={<VerifiedIcon />}
            text={`Version ${process.env.REACT_APP_MI_CONSULTORIO_VERSION} ${
              process.env.REACT_APP_MI_CONSULTORIO_VERSION_BETA_TEXT || ''
            }`}
            iconColor={colorVersion}
            textColor={colorVersion}
          />
        </List>
      </Box>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      {/* <CssBaseline /> */}
      <AppBar
        position='fixed'
        sx={{
          ml: { md: `${drawerWidth}px` },
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Link
            to='/'
            style={{
              textDecoration: 'none',
              color: 'white',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Avatar alt='' src={logo} />
            <Typography variant='h6' noWrap component='div'>
              Mi Consultorio
              <Typography mx={1} variant='overline' gutterBottom={false}>
                {process.env.REACT_APP_MI_CONSULTORIO_VERSION_BETA_TEXT || ''}
              </Typography>
            </Typography>
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          {process.env.REACT_APP_STAGE === 'staging' && (
            <Tooltip key='t1' title='Ambiente de prueba (server staging)' enterTouchDelay={0}>
              <React.Fragment>
                <Chip
                  size='small'
                  color='warning'
                  icon={<Code />}
                  label={'Ambiente de prueba'}
                  sx={{
                    display: { xs: 'none', sm: 'inline-flex' },
                  }}
                />
                <Chip
                  size='small'
                  color='warning'
                  label={'Test'}
                  sx={{
                    display: { xs: 'inline-flex', sm: 'none' },
                  }}
                />
              </React.Fragment>
            </Tooltip>
          )}
          {process.env.REACT_APP_STAGE === 'development' && (
            <Tooltip key='t2' title='Ambiente de prueba (server local)' enterTouchDelay={0}>
              <React.Fragment>
                <Chip
                  size='small'
                  color='info'
                  icon={<Code />}
                  label={'Ambiente de prueba'}
                  sx={{
                    display: { xs: 'none', sm: 'inline-flex' },
                  }}
                />
                <Chip
                  size='small'
                  color='info'
                  label={'Test'}
                  sx={{
                    display: { xs: 'inline-flex', sm: 'none' },
                  }}
                />
              </React.Fragment>
            </Tooltip>
          )}
          <TopMenu list={TopMenuList} />
        </Toolbar>
        <Loader id='headerLoader' />
      </AppBar>
      <Box component='nav' sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Container
        id='main'
        component='main'
        sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}
        maxWidth='lg'
      >
        <Toolbar />
        {props.children}
      </Container>
    </Box>
  );
};

export default Menu;
