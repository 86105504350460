import { useEffect, useState } from 'react';
import { generarTokenConsulta } from '../../services/Seo/OrdenDigital';
import { getImprimirOrden } from '../../services/Seo/Orden';
import { Box, Grid } from '@mui/material';
import { SUCCESS, WARNING } from '../../@iosper/components/LoadingButton/LoadingAlert';
import { useForm } from 'react-hook-form';
import { useUser } from '../Auth/User';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import OrdenDigitalQRMessageEvent from './OrdenDigitalQRMessageEvent';
import OrdenDigitalQRPreview from './OrdenDigitalQRPreview';
import OrdenPreview from './OrdenPreview';
import PrestadorSelect from '../Prestador/PrestadorSelect';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Typography from '@mui/material/Typography';
import { UltimosPrestadoresOrden } from '../Prestador/UltimosPrestadoresOrden';
import { getPrestadorLogueado } from '../../services/Busquedas/Prestadores';
import PrestadorAutocompleteVirtualizado from '../Prestador/PrestadorAutocompleteVirtualizado';
import { LoadingButton } from '@mui/lab';
import { getConsultorioLogueado } from '../../services/Busquedas/Prestadores';
import { VideoModal } from '../Common/VideoModal';

const EmitirOrdenDigital = () => {
  const { user } = useUser();

  const [keyComponentSelectPrestador, setKeyComponentSelectPrestador] = useState(0);
  const [loadingOrden, setLoadingOrden] = useState(false);
  const [selectedPrestador, setSelectedPrestador] = useState(null);
  const [prestadorLogueado, setPrestadorLogueado] = useState(null);
  const [consultorioLogueado, setConsultorioLogueado] = useState(null);
  const [socket, setSocket] = useState(null);

  // Dialog Orden Preview
  const [datosOrdenGenerada, setDatosOrdenGenerada] = useState('');
  const [openDialogReporte, setOpenDialogReporte] = useState(false);
  const [urlReporte, setUrlReporte] = useState('');

  // Dialog Orden QR Preview
  const [openQRPreview, setOpenQRPreview] = useState(false);
  const [qrValue, setQrValue] = useState('');

  // Dialog QR Messages Event Preview
  const [messageQr, setMessageQr] = useState('');
  const [qrMessageEventOpen, setQrMessageEventOpen] = useState(false);
  const [qrMessageEventStatus, setQrMessageEventStatus] = useState();

  useEffect(() => {
    if (socket != null) {
      socket.onmessage = function (e) {
        const mssg = JSON.parse(e.data);
        if (mssg.token === qrValue) {
          // dependiendo el state es la vista que va a mostrar
          switch (mssg.state) {
            case 'ErrorOrden':
            case 'ErrorQrEscaneado':
            case 'OrdenCancelada': {
              setOpenQRPreview(false);
              setQrMessageEventOpen(true);
              setMessageQr(mssg.message);
              setQrMessageEventStatus(WARNING);
              socket.close();
              break;
            }
            case 'OrdenGenerada': {
              const orden = mssg.data;
              setQrMessageEventOpen(false);
              setDatosOrdenGenerada(orden);
              imprimirOrden(orden);
              socket.close();
              break;
            }
            case 'QrEscaneado': {
              setOpenQRPreview(false);
              setQrMessageEventOpen(true);
              setQrMessageEventStatus(SUCCESS);
              setMessageQr(mssg.message);
              break;
            }
            default:
              socket.close();
          }
        }
      };
    }
  }, [socket]);

  useEffect(() => {
    if (socket != null) {
      //  socket.close();
    }

    if (qrValue !== '') {
      conectarWebSocket(qrValue);
    }
  }, [qrValue]);

  useEffect(() => {
    const getPrestador = async () => {
      const prestador = await getPrestadorLogueado();
      if (prestador != null) {
        setPrestadorLogueado(prestador);
        setSelectedPrestador(prestador);
      } else {
        const consultorio = await getConsultorioLogueado();
        if (consultorio != null) {
          setConsultorioLogueado(consultorio);
        }
      }
    };

    if (prestadorLogueado === null) {
      getPrestador();
    }
  }, []);

  const conectarWebSocket = (qrValue) => {
    setSocket(
      new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}?tokenQr=${qrValue}&token=${localStorage.getItem(
          'token',
        )}`,
      ),
    );
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const handlerLimpiar = () => {
    reset();
    setSelectedPrestador(null);
    setKeyComponentSelectPrestador(1 + Math.random() * (1000 - 1));
    setLoadingOrden(false);
    socket.close();
  };

  const handlerCerrarQRMessageEvent = () => {
    setQrMessageEventOpen(false);
    handlerLimpiar();
  };

  const handlerCerrarQRPreview = () => {
    setOpenQRPreview(false);
    handlerLimpiar();
  };

  const handlerCerrarPreview = () => {
    handlerLimpiar();
  };

  const onSubmit = async (data, event) => {
    setLoadingOrden(true);
    event.preventDefault();

    try {
      const tokenQr = await generarTokenConsulta(getDataOrdenDigital());
      setQrValue(tokenQr);
      setOpenQRPreview(true);
    } catch (e) {
      setLoadingOrden(false);
    }
  };

  const imprimirOrden = async (token) => {
    const resGenerada = await getImprimirOrden({
      numeroOrden: token.orden.numeroOrdenSinFormato,
      codigoTransaccion: token.orden.codigoTransaccion,
    });

    setUrlReporte(resGenerada);
    setOpenDialogReporte(true);
  };

  const getDataOrdenDigital = () => ({
    codigoPrestador: selectedPrestador.matricula,
    tipoPrestador: selectedPrestador.tipo,
    nombreUsuario: user?.nombre,
  });

  const validatePrestador = () => {
    // Si no esta activo seguimos mostrando que debe seleccionar un prestador
    return selectedPrestador !== null;
  };

  const handlerSelectPrestador = (prestador) => {
    setSelectedPrestador(prestador);
  };

  const handlerUltimoPrestador = (prestador) => {
    handlerSelectPrestador(prestador);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5'>
              Generar Orden de Consulta Electrónica
            </Typography>
            <Typography gutterBottom>
              Acá podrás generar un QR para ser leído por el afiliado en su aplicación mi.iosper.
            </Typography>
            <VideoModal
              sx={{ pt: 1 }}
              src={'https://www.youtube.com/embed/Vo6rnsbA9-I?si=xO8Yttf3QLqrSBLa&autoplay=1'}
            />
          </CardContent>

          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Grid container spacing={3} alignItems='center'>
                <Grid item xs={prestadorLogueado?.idProfesional ? 12 : 11}>
                  {prestadorLogueado?.idProfesional || consultorioLogueado?.idConsultorio ? (
                    <PrestadorSelect
                      key={keyComponentSelectPrestador + '_prestador'}
                      value={selectedPrestador}
                      disabled={selectedPrestador !== null ?? true}
                      name='prestadorRegister'
                      {...register('prestadorRegister', {
                        validate: () => selectedPrestador?.idPrestador || false,
                      })}
                      onSelectPrestador={handlerSelectPrestador}
                    />
                  ) : (
                    <PrestadorAutocompleteVirtualizado
                      key={keyComponentSelectPrestador + '_prestador'}
                      value={selectedPrestador}
                      disabled={prestadorLogueado !== null ?? true}
                      name='prestadorRegister'
                      {...register('prestadorRegister', {
                        validate: () => selectedPrestador !== null || false,
                      })}
                      onSelectedPrestador={handlerSelectPrestador}
                    />
                  )}
                </Grid>
                {!prestadorLogueado?.idProfesional && (
                  <Grid item xs={1}>
                    <UltimosPrestadoresOrden onSelectEvent={handlerUltimoPrestador} />
                  </Grid>
                )}
              </Grid>
              {errors.prestadorRegister && errors.prestadorRegister.type === 'validate' && (
                <p style={{ color: 'red' }}>Debe seleccionar el prestador</p>
              )}
            </CardContent>
            <CardActions sx={{ px: 2 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: 2,
                    }}
                  >
                    <LoadingButton
                      variant='contained'
                      size='medium'
                      color='primary'
                      startIcon={<QrCodeIcon />}
                      type='submit'
                      loading={loadingOrden}
                    >
                      Generar
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </CardActions>
          </form>
        </Card>

        {/* QR */}
        <OrdenDigitalQRPreview
          open={openQRPreview}
          setOpen={openQRPreview}
          qrValue={qrValue}
          prestador={selectedPrestador}
          handlerCerrarPreview={handlerCerrarQRPreview}
        ></OrdenDigitalQRPreview>
        {/* Messages */}
        <OrdenDigitalQRMessageEvent
          open={qrMessageEventOpen}
          prestador={selectedPrestador}
          message={messageQr}
          status={qrMessageEventStatus}
          onHandleCerrar={handlerCerrarQRMessageEvent}
        ></OrdenDigitalQRMessageEvent>
        {/* Orden */}
        <OrdenPreview
          open={openDialogReporte}
          setOpen={setOpenDialogReporte}
          urlReporte={urlReporte}
          datosOrden={datosOrdenGenerada}
          nombreReporte={'Orden_iosper_' + datosOrdenGenerada.numeroOrden}
          handlerCerrarPreview={handlerCerrarPreview}
          showDownload={false}
          showPrint={false}
          showSuccessIcon
        ></OrdenPreview>
      </Grid>
    </Grid>
  );
};

export default EmitirOrdenDigital;
