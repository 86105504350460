import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { OrdenVariasForm } from './components/OrdenVariasForm';
import CustomizedSnackbars from '../Notifications/SnackBar';

const EmitirOrdenVaria = () => {
  const [messageStatus, setMessageStatus] = useState({
    status: '',
    message: null,
  });

  const onHandleSaveResult = ({ message = null }) => {
    setMessageStatus({ status: message?.status || 'success', message: message?.message || null });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5'>
              Nueva Orden de Prácticas
            </Typography>
            <Typography gutterBottom>
              Acá podrás emitir órdenes de prácticas médicas y bioquímicas ambulatorias, para luego
              descargarlas, imprimirlas y/o enviarlas por correo electrónico.
            </Typography>
          </CardContent>

          <OrdenVariasForm onSaveEvent={(eventResult) => onHandleSaveResult(eventResult)} />
        </Card>
      </Grid>

      {messageStatus?.message && (
        <CustomizedSnackbars
          open={messageStatus?.status}
          autoHideDuration={8000}
          severity={messageStatus?.status || 'success'}
          message={messageStatus?.message}
        />
      )}
    </Grid>
  );
};

export default EmitirOrdenVaria;
