import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { forwardRef } from 'react';

export const isValidPracticaValue = (value) => {
  return (
    value &&
    typeof value === 'object' &&
    'codigo' in value &&
    'idPractica' in value &&
    'descripcion' in value
  );
};

/**
 * @typedef {Object} PracticasAutocomplete
 * @property {Object} value - recibe como valor el objeto Practica para setear en la lista, si se encuentra.
 * @property {Object} error - si se recibe el objeto de error entonces se esta indicante ese mismo estado {message}.
 * @property {function} onChangeEvent - Función que se ejecuta cuando cambia la selección.
 * @property {('byOrdenesConsultas'|'byOrdenesPracticas'|'byPrescripciones')} filterByAPI - nombre del filtro segun sector o negocio que se quiere aplicar.
 * @property {String} filterByTipoPractica - código de tipo de practica para aplicar filtro a la lista.
 */

/**
 * @param {PracticasAutocomplete} props
 */
export const PracticasAutocomplete = forwardRef(
  ({ value, error, onChangeEvent, options, loading, ...props }, ref) => {
    const isValidValue = (val) => {
      return isValidPracticaValue(val) && val.codigo !== '1';
    };

    const validValue = isValidValue(value) ? value : null;

    const handleChange = (newValue) => {
      if (onChangeEvent) {
        onChangeEvent(newValue);
      }
    };

    return (
      <Autocomplete
        {...props}
        ref={ref}
        value={validValue}
        isOptionEqualToValue={(option, value) => option?.idPractica === value?.idPractica}
        getOptionLabel={(option) => {
          if (!option?.codigo || !option?.descripcion) {
            return '';
          }
          let label = `${option.codigo} - ${option.descripcion}`;
          if (option.incluirPracticasSinConvenio && option.tieneConvenio === false) {
            label += ' (sin convenio)';
          }
          return label;
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {`${option.codigo} - ${option.descripcion}`}
            {option.incluirPracticasSinConvenio && option.tieneConvenio === false && (
              <strong>&nbsp;(sin convenio)</strong>
            )}
          </li>
        )}
        options={options}
        loading={loading}
        onChange={(_, newValue) => handleChange(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={'Práctica'}
            variant='standard'
            error={!!error}
            helperText={error ? error.message : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color='inherit' size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  },
);
