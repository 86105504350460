import { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Fab,
  formLabelClasses,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { FormatListNumbered } from '@mui/icons-material';
import {
  getUltimosDiagnosticos,
  getUltimosDiagnosticosPrescripcionElectronica,
} from '../../services/Busquedas/Diagnosticos';

export const UltimosDiagnoticos = ({
  idAfiliado = null,
  limit = 10,
  onSelectEvent = null,
  isRecetaElectronica = true,
  isPrescripcionElectronica = false,
  disabled = false,
}) => {
  const [latestOption, setLatestOption] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    getLatestOptions();
  }, [idAfiliado]);

  const getLatestOptions = async () => {
    let query = `?limit=${limit}`;
    if (idAfiliado) {
      query = query + `&idAfiliado=${idAfiliado}`;
    }

    let result = [];
    if (isRecetaElectronica && !isPrescripcionElectronica) {
      result = (await getUltimosDiagnosticos(query)) || [];
    }

    if (isPrescripcionElectronica) {
      result = (await getUltimosDiagnosticosPrescripcionElectronica(query)) || [];
    }

    if (result?.length > 0) {
      setLatestOption(result);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHandleSelect = (cie10) => {
    setAnchorEl(null);
    if (onSelectEvent) {
      onSelectEvent(cie10);
    }
  };

  return (
    <>
      <Box
        sx={{
          height: { sm: '100%' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Tooltip title='Últimos diagnósticos recetados'>
          <Fab
            onClick={handleClick}
            size='small'
            color='default'
            disabled={disabled}
            sx={{ boxShadow: 'none' }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <FormatListNumbered fontSize='small' color='primary' />
          </Fab>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {latestOption.map((cie10) => (
          <MenuItem key={cie10.idCie10} onClick={(e) => onHandleSelect(cie10)}>
            ({cie10.codigo}) {cie10.descripcion}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
