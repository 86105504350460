import { useEffect, useState } from 'react';
import { findPrescripcionesElectronicas } from '../../../services/Busquedas/PrescripcionesElectronicas';

const dataDefaultValues = {
  idAfiliado: null,
  documento: '',
  fechaDesde: '',
  fechaHasta: '',
};

export const useBuscarPrescripcionElectronica = ({
  dataInit = dataDefaultValues,
  idAfiliado,
  locationState,
}) => {
  const defaultData = {
    ...dataInit,
    idAfiliado: idAfiliado || dataInit.idAfiliado,
  };
  const [dataForm, setDataForm] = useState(defaultData);
  const [prescripcionesElectronicas, setPrescripcionesElectronicas] = useState([]);
  const [messageStatus, setMessageStatus] = useState({
    status: '',
    message: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [isFromNuevaPrescripcionElectronica, setIsFromNuevaPrescripcionElectronica] =
    useState(false);
  const [documento, setDocumento] = useState(null);

  useEffect(() => {
    if (
      prescripcionesElectronicas.length > 0 &&
      prescripcionesElectronicas[0].personaFisicaAfiliado?.numeroDocumento
    ) {
      setDocumento(prescripcionesElectronicas[0].personaFisicaAfiliado.numeroDocumento);
    }
  }, [prescripcionesElectronicas]);

  useEffect(() => {
    if (idAfiliado) {
      setIsFromNuevaPrescripcionElectronica(true);

      try {
        buscarPrescripcionesElectronicas(dataForm);
      } catch (e) {
        setIsLoading(false);
      }
    }

    if (locationState?.prescripcionesElectronicas?.length) {
      setShowForm(false);
      setPrescripcionesElectronicas(locationState.prescripcionesElectronicas);
    }
  }, []);

  /**
   * Metodo que busca las recetas electronicas segun parametros del form
   * @param {object} data
   */
  const buscarPrescripcionesElectronicas = async (data) => {
    try {
      setShowForm(false);
      const result = await findPrescripcionesElectronicas(data);
      setPrescripcionesElectronicas(result);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Metodo que busca las recetas
   * @param {object} data
   */
  const handlerFindPrescripcionesElectronicasSubmit = async (data) => {
    setPrescripcionesElectronicas([]);
    setIsLoading(true);
    try {
      const result = await findPrescripcionesElectronicas({
        ...data,
      });
      setPrescripcionesElectronicas(result);

      if (!result || result.length < 1) {
        setMessageStatus({
          status: 'info',
          message: 'Busqueda sin resultados, intente con otros parámetros.',
        });
      } else {
        setShowForm(false);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  /**
   * Limpia el listado de recetas y muestra el formulario par nueva busqueda
   */
  const onHandlerBackToForm = () => {
    setShowForm(true);
  };

  /**
   * Carga y muestra un mensaje
   */
  const setAndShowMessage = ({ status = '', message = '' }) => {
    setMessageStatus({ status, message });
  };

  /**
   * Limpia los mensajes
   */
  const onHandlerCleanMessage = () => {
    setMessageStatus({ status: '', message: '' });
  };

  const updateIdAfiliado = (newIdAfiliado) => {
    setDataForm((prevData) => ({
      ...prevData,
      idAfiliado: newIdAfiliado,
    }));
  };

  return {
    dataForm,
    prescripcionesElectronicas,
    messageStatus,
    isLoading,
    showForm,
    isFromNuevaPrescripcionElectronica,
    setAndShowMessage,
    onHandlerBackToForm,
    onHandlerCleanMessage,
    handlerFindPrescripcionesElectronicasSubmit,
    /**updateIdAfiliado, documento,setDocumento : usados para actualizar el formulario dependiendo si viene de nueva receta o no */
    updateIdAfiliado,
    documento,
    setDocumento,
  };
};
