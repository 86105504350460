import {
  Box,
  Button,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  HighlightOff as HighlightOffIcon,
  History as HistoryIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { TipoPracticaAutocomplete } from '../../Practicas/TipoPracticaAutocomplete';
import BuscarAfiliado from '../../Afiliado/BuscarAfiliado';
import DateTextInput from '../../Common/DateTextInput';
import DiagnosticoSelector from '../../Diagnostico/DiagnosticoSelector';
import { usePrescripcionElectronicaForm } from '../hooks/usePrescripcionElectronicaForm';
import { UltimasPrescripcionesElectronicas } from './UltimasPrescripcionesElectronicas';
import { UltimosDiagnoticos } from '../../Diagnostico/UltimosDiagnoticos';
import { isMobile } from '../../../@iosper/utils/DeviceDetector';
import PrestadorSelect from '../../Prestador/PrestadorSelect';
import { PracticasPorTipoAutocomplete } from '../../Practicas/PracticasPorTipoAutocomplete';
import { useState } from 'react';

const customStyle = {
  marginBottom: '0px',
  marginTop: '0px',
};

export const PrescripcionForm = ({
  dataForm = null,
  onSaveEvent,
  onHistoryClickEvent,
  esRecodificacion = false,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const esMobile = isMobile() || !matches;
  const { stateDataForm, isLoading, messageStatus, save } = usePrescripcionElectronicaForm({
    initData: dataForm,
  });
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    reset,
    resetField,
    setError,
    trigger,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    values: stateDataForm,
  });
  const { append, remove, fields } = useFieldArray({
    control,
    name: 'practicas',
    rules: {
      required: {
        value: true,
        message: 'Debe ingresar una práctica',
      },
    },
  });

  const [tienePrescripciones, setTienePrescripciones] = useState(false);
  const watchAfiliado = watch('afiliado');
  const [openDialog, setOpenDialog] = useState(false);
  const [practicasSinConvenio, setPracticasSinConvenio] = useState([]);

  const onSubmit = async (data) => {
    data.esRecodificacion = esRecodificacion;
    const result = await save(data);
    if (onSaveEvent && !isLoading) {
      onSaveEvent({
        data: result,
        message: messageStatus,
        isLoading,
      });
    }

    if (result && !isLoading) {
      reset();
    }
  };

  const onHandleClickLimpiar = () => {
    reset();
  };

  const handleHistorial = () => {
    if (onHistoryClickEvent && !esRecodificacion) {
      onHistoryClickEvent(tienePrescripciones ? getValues() : null);
    }
  };

  const handlerSelectPrestador = (prestador) => {};

  const validateDateRange = (value) => {
    if (esRecodificacion) return true;

    const startDate = new Date(`${value} 00:00:00`);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (startDate >= today) {
      return true;
    } else {
      return 'El valor debe ser igual o posterior a la fecha actual';
    }
  };

  const handleDateChange = (value) => {
    const isValid = validateDateRange(value);
    if (isValid === true) {
      setValue('fechaInicio', value);
    }
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const handleFirmarElectronico = () => {
    const practicas = getValues('practicas') || [];
    const sinConvenio = practicas.filter((p) => p?.practica?.tieneConvenio === false);

    if (sinConvenio.length > 0) {
      setPracticasSinConvenio(sinConvenio);
      setOpenDialog(true);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const handleConfirmDialog = () => {
    setOpenDialog(false);
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <form style={customStyle} onSubmit={handleSubmit(onSubmit)}>
        <CardContent style={customStyle} sx={{ paddingBottom: 0 }}>
          <Grid container spacing={2} rowSpacing={1}>
            <Grid item container spacing={2} rowSpacing={1} alignItems='flex-start'>
              {/* Componente buscar afiliado */}
              <Grid item xs={12} md={getValues('afiliado') && !esRecodificacion ? 10 : 12}>
                <BuscarAfiliado
                  style={customStyle}
                  initialValue={getValues('afiliado')}
                  onSelectAfiliado={(value) => {
                    setValue('afiliado', value, { shouldDirty: true });
                    return false;
                  }}
                  {...register('afiliado', {
                    validate: (value) => !!value || 'Debe seleccionar un afiliado',
                  })}
                  disabled={esRecodificacion}
                />
                {errors.afiliado && (
                  <p style={{ color: 'red', marginTop: '1px' }}>{errors.afiliado.message}</p>
                )}
              </Grid>
              {!esRecodificacion && (
                <>
                  <Grid
                    item
                    xs={!esMobile && getValues('afiliado') ? 2 : 1}
                    md={!esMobile && getValues('afiliado') ? 1 : 1}
                    sx={{
                      display: !esMobile && getValues('afiliado') ? 'grid' : 'none',
                      marginTop: '-12px',
                      marginLeft: 2,
                    }}
                  >
                    <UltimasPrescripcionesElectronicas
                      idAfiliado={getValues('afiliado')?.idAfiliado || null}
                      onSelectEvent={(value) => {
                        setValue('tipoPractica', value.tipoPracticaCategoria, {
                          shouldDirty: true,
                        });
                        setValue('practicas', value.practicas, { shouldDirty: true });
                        setValue('cie10', value.cie10, { shouldDirty: true });
                        setValue(
                          'cie10Secundario',
                          value?.cie10Secundario?.id ? value.cie10Secundario : null,
                          { shouldDirty: true },
                        );
                        setValue(
                          'observacion',
                          value?.prescripcionElectronica?.observacion || null,
                          {
                            shouldDirty: true,
                          },
                        );
                      }}
                      onTienePrescripcionesEvent={(value) => setTienePrescripciones(value)}
                      disabled={esRecodificacion}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={!esMobile && getValues('afiliado') ? 2 : 1}
                    md={!esMobile && getValues('afiliado') ? 1 : 1}
                    sx={{
                      display: !esMobile && getValues('afiliado') ? 'grid' : 'none',
                      marginTop: '-12px',
                      marginLeft: -1,
                      marginRight: -2,
                      paddingRight: -1,
                    }}
                  >
                    <Tooltip title='Historial'>
                      <Fab
                        onClick={handleHistorial}
                        size='small'
                        color='default'
                        sx={{ boxShadow: 'none' }}
                      >
                        <HistoryIcon fontSize='small' color='primary' />
                      </Fab>
                    </Tooltip>
                  </Grid>
                </>
              )}

              {/* componente prescriptor (solo se muestra en la recodificación) */}
              <Grid item xs={12} md={getValues('prescriptor') ? 10 : 12}>
                {esRecodificacion && (
                  <PrestadorSelect
                    label={'Prescriptor'}
                    key={'prescripcion_prestador'}
                    value={getValues('profesional')}
                    disabled={esRecodificacion}
                    name='prestadorRegister'
                    onSelectPrestador={handlerSelectPrestador}
                  />
                )}
              </Grid>

              {/* Componente autocomplete tipo de prácticas */}
              <Grid item xs={12} md={7}>
                <TipoPracticaAutocomplete
                  value={getValues('tipoPractica') || null}
                  onChangeOption={(value) => {
                    setValue('tipoPractica', value, { shouldDirty: true });
                    remove();
                    setValue('practica', null, { shouldDirty: false });
                    setValue('cantidad', 1, { shouldDirty: false });
                    trigger('tipoPractica');
                  }}
                  {...register('tipoPractica', {
                    validate: (value) => !!value || 'Debe seleccionar un tipo de práctica',
                  })}
                  disabled={esRecodificacion}
                />
                {errors.tipoPractica && (
                  <p style={{ color: 'red', marginTop: '1px' }}>{errors.tipoPractica.message}</p>
                )}
              </Grid>

              {/* Componente de Fecha Inicio vigencia */}
              <Grid item xs={12} md={5}>
                <FormControl fullWidth variant='standard'>
                  <DateTextInput
                    register={register}
                    errors={errors}
                    required={true}
                    label='Inicio de vigencia'
                    fieldName='fechaInicio'
                    change={handleDateChange}
                    errorStyle={{ marginTop: '1px' }}
                    validations={{
                      dateOutRange: validateDateRange,
                    }}
                    disabled={esRecodificacion}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>

        <CardContent style={customStyle} sx={{ paddingY: 1, paddingX: { xs: 0, sm: 2 } }}>
          {/* Prácticas */}
          <Grid item container spacing={2} rowSpacing={1} sx={{ paddingTop: '0px' }} xs={12} mb={2}>
            <Grid item xs={12}>
              <Box
                p={2}
                mt={2}
                sx={{
                  borderRadius: { xs: 0, sm: '4px' },
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderLeftWidth: { xs: 0, sm: 1 },
                  borderRightWidth: { xs: 0, sm: 1 },
                  overflow: 'hidden',
                  background: 'rgb(241 241 241 / 35%)',
                }}
              >
                <Grid container alignItems={'flex-start'} spacing={2} rowSpacing={1}>
                  <Grid item md={7} sm={7} xs={12}>
                    <PracticasPorTipoAutocomplete
                      value={getValues(`practica`)}
                      disabled={!getValues('tipoPractica')}
                      filterByTipoPractica={
                        getValues('tipoPractica')?.idGrupoInosPracticaCategoria || null
                      }
                      onChangeEvent={(value) => {
                        setValue(`practica`, value, {
                          shouldDirty: true,
                        });
                        trigger('practica');
                      }}
                      {...register(`practica`, {
                        validate: {
                          uniqueValue: (value) => {
                            const practicasList = fields;
                            const result = practicasList.filter(
                              (p) => !!value && value?.idPractica === p?.practica?.idPractica,
                            );
                            if (result?.length > 0) {
                              return 'La práctica se encuentra repetida';
                            }
                            return true;
                          },
                        },
                      })}
                    />
                    {errors.practica && (
                      <p style={{ color: 'red', marginTop: '1px' }}>{errors.practica.message}</p>
                    )}
                  </Grid>
                  <Grid item lg={3} md={2} sm={2} xs={12}>
                    <FormControl fullWidth variant='standard'>
                      <InputLabel htmlFor='cantidad-practica'>Cantidad</InputLabel>
                      <Input
                        id='cantidad-practica'
                        aria-describedby='Cantidad de unidades del practica seleccionado.'
                        type='number'
                        defaultValue={getValues(`cantidad`) || 1}
                        onChange={(value) => {
                          setValue(`cantidad`, value, {
                            shouldDirty: true,
                          });
                        }}
                        inputProps={{
                          step: 1,
                          type: 'number',
                        }}
                        {...register(`cantidad`, {
                          validate: (value) => !!value && value > 0,
                        })}
                      />
                      {errors.cantidad && (
                        <p style={{ color: 'red', marginTop: '1px' }}>Debe ingresar cantidad</p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item lg={2} md={3} sm={3} xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        pt: 2,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        variant='contained'
                        color='primary'
                        size='medium'
                        startIcon={<AddIcon />}
                        disabled={
                          !getValues('tipoPractica') || !!errors.practica || !!errors.cantidad
                        }
                        onClick={() => {
                          if (!getValues('practica')) {
                            setError('practica', {
                              type: 'validate',
                              message: 'Debe ingresar la práctica',
                            });
                          }
                          if (errors.practica || errors.cantidad) return;
                          append({
                            practica: getValues('practica'),
                            cantidad: getValues('cantidad'),
                          });
                          resetField('practica');
                        }}
                      >
                        Agregar
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    {fields?.length > 0 && (
                      <TableContainer>
                        <Table
                          sx={{ minWidth: 200, width: '100%' }}
                          size='small'
                          aria-label='a dense table'
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align='center'
                                sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                              >
                                Item
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell
                                align='center'
                                sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                              >
                                Cantidad
                              </TableCell>
                              <TableCell align='right'></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {fields.map((row, index) => (
                              <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell
                                  align='center'
                                  sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  component='th'
                                  scope='row'
                                  sx={{ paddingX: { xs: '0px', sm: '16px' } }}
                                >
                                  <Typography
                                    variant='span'
                                    sx={{ display: { xs: 'inline', sm: 'none' } }}
                                  >
                                    {`#${index + 1} - `}
                                  </Typography>
                                  (<b>{row?.practica?.codigo}</b>) {row?.practica?.descripcion}{' '}
                                  {row?.practica?.tieneConvenio === false && (
                                    <Typography
                                      component='span'
                                      sx={{ color: 'orange', fontWeight: 'bold' }}
                                    >
                                      &nbsp;(sin convenio)
                                    </Typography>
                                  )}
                                  <Grid sx={{ display: { xs: 'block', sm: 'none' } }}>
                                    <b>Cantidad {row?.cantidad}</b>
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  align='center'
                                  sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                                >
                                  <b>{row?.cantidad}</b>
                                </TableCell>
                                <TableCell
                                  align='right'
                                  sx={{ paddingRight: { xs: '0px', sm: '16px' } }}
                                >
                                  <Button
                                    sx={{
                                      display: { xs: 'inline-flex', sm: 'none' },
                                      minWidth: '24px',
                                      padding: '10px',
                                      '& span': { margin: 0 },
                                    }}
                                    aria-label='Eliminar práctica.'
                                    variant='contained'
                                    color='neutral'
                                    size='small'
                                    startIcon={<DeleteIcon />}
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  ></Button>
                                  <Button
                                    sx={{
                                      display: { xs: 'none', sm: 'inline-flex' },
                                    }}
                                    aria-label='Eliminar práctica.'
                                    variant='contained'
                                    color='neutral'
                                    size='small'
                                    startIcon={<DeleteIcon />}
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    Eliminar
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                </Grid>
              </Box>
              {errors?.practicas?.root && (
                <Typography sx={{ color: 'red', marginTop: '1px', paddingLeft: { xs: 2, sm: 0 } }}>
                  {errors.practicas.root.message}
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>

        <CardContent style={customStyle} sx={{ paddingTop: 0 }}>
          <Grid container spacing={2} rowSpacing={1}>
            <Grid item container spacing={2} rowSpacing={1} alignItems='flex-start'>
              {/* Componente de busqueda de diagnostico obligatorio */}
              <Grid item xs={12}>
                <Grid container spacing={2} rowSpacing={1} alignItems={'center'}>
                  <Grid item sm={!esRecodificacion ? 10 : 12} xs={!esRecodificacion ? 9 : 12}>
                    <DiagnosticoSelector
                      label='Buscá el diagnóstico principal'
                      name='cie10'
                      {...register('cie10', {
                        validate: {
                          required: (value) => !!value || 'Debe seleccionar un diagnóstico',
                          uniqueValue: (value) => {
                            const c2 = getValues('cie10Secundario');
                            if (!!value && !!c2 && value?.idCie10 === c2?.idCie10) {
                              return 'El diagnóstico se encuentra repetido';
                            }
                            return true;
                          },
                        },
                      })}
                      diagnostico={getValues('cie10')}
                      onSelectedDiagnostico={(value) => {
                        setValue('cie10', value, { shouldDirty: true });
                      }}
                      disabled={esRecodificacion}
                    />
                    {errors.cie10 && (
                      <p style={{ color: 'red', marginTop: '1px' }}>{errors.cie10.message}</p>
                    )}
                  </Grid>

                  {!esRecodificacion && (
                    <Grid item sm={2} xs={3}>
                      <UltimosDiagnoticos
                        isPrescripcionElectronica
                        idAfiliado={getValues('afiliado')?.idAfiliado}
                        onSelectEvent={(value) => {
                          setValue('cie10', value, { shouldValidate: true, shouldDirty: true });
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {/* Componente de busqueda de diagnostico secundario */}
              <Grid item xs={12}>
                <Grid container spacing={2} rowSpacing={1} alignItems={'center'}>
                  <Grid item sm={!esRecodificacion ? 10 : 12} xs={!esRecodificacion ? 9 : 12}>
                    <DiagnosticoSelector
                      label='Buscá el diagnóstico secundario (opcional)'
                      name='cie10Secundario'
                      {...register('cie10Secundario', {
                        validate: {
                          uniqueValue: (value) => {
                            const c1 = getValues('cie10');
                            if (!!value && !!c1 && value?.idCie10 === c1?.idCie10) {
                              return 'El diagnóstico se encuentra repetido';
                            }
                            return true;
                          },
                        },
                      })}
                      diagnostico={getValues('cie10Secundario')}
                      onSelectedDiagnostico={(value) => {
                        setValue('cie10Secundario', value, { shouldDirty: true });
                      }}
                      disabled={esRecodificacion}
                    />
                    {errors.cie10Secundario && (
                      <p style={{ color: 'red', marginTop: '1px' }}>
                        {errors.cie10Secundario.message}
                      </p>
                    )}
                  </Grid>

                  {!esRecodificacion && (
                    <Grid item sm={2} xs={3}>
                      <UltimosDiagnoticos
                        isPrescripcionElectronica
                        idAfiliado={getValues('afiliado')?.idAfiliado}
                        onSelectEvent={(value) => {
                          setValue('cie10Secundario', value, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {/* Componente de textarea para observaciones */}
              <Grid item xs={12}>
                <FormControl fullWidth variant='standard'>
                  <Controller
                    control={control}
                    name='observacion'
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          label='Indicaciones (Opcional)'
                          placeholder='Indicaciones ...'
                          variant='standard'
                          multiline
                          fullWidth
                          inputProps={{
                            maxLength: 100,
                          }}
                          value={field?.value || ''}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          disabled={esRecodificacion}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              {/* Componente de textarea para observaciones para la RECODIFICACION */}
              {esRecodificacion && (
                <Grid item xs={12}>
                  <FormControl fullWidth variant='standard'>
                    <Controller
                      control={control}
                      name='observacionRecodificacion'
                      render={({ field }) => {
                        return (
                          <TextField
                            {...field}
                            label='Observaciones'
                            placeholder='Observaciones ...'
                            variant='standard'
                            multiline
                            fullWidth
                            inputProps={{
                              maxLength: 100,
                            }}
                            value={field?.value || ''}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ px: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 2,
                }}
              >
                <Button
                  variant='contained'
                  color='neutral'
                  disabled={esRecodificacion}
                  size='medium'
                  startIcon={<HighlightOffIcon />}
                  onClick={() => onHandleClickLimpiar()}
                >
                  Limpiar
                </Button>

                <LoadingButton
                  variant='contained'
                  size='medium'
                  color='primary'
                  startIcon={<SaveIcon />}
                  loading={isLoading}
                  onClick={handleFirmarElectronico}
                >
                  Firmar electrónicamente
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </form>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          Prácticas sin convenio
        </DialogTitle>
        <DialogContent>
          <p>Las siguientes practicas no tienen convenio con IOSPER:</p>
          <ul>
            {practicasSinConvenio.map((p, index) => (
              <li key={index}>
                ({p.practica.codigo}) {p.practica.descripcion}
              </li>
            ))}
          </ul>
          <p>¿Desea incluirlas en la prescripción de todos modos?</p>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='neutral' onClick={handleCloseDialog}>
            Cancelar
          </Button>
          <Button variant='contained' color='primary' onClick={handleConfirmDialog}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
