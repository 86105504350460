import { useState } from 'react';
import {
  generarPrescripcionElectronica,
  generarRecodificacionPrescripcionElectronica,
  getReportePrescripcionElectronica,
} from '../../../services/PrescripcionElectronica/PrescripcionElectronica';

const initDataForm = {
  afiliado: null, //para save: int -> idAfiliado
  tipoPractica: null, // no es para guardar, solo filtra las practicas
  practicas: [], //para save: array -> HistorialPracticaPrescripcionElectronicaVO {idPractica, cantidad}
  fechaInicio: null, //para save:string -> yyyy-mm-dd
  cie10: null, // para save: object -> cie10
  cie10Secundario: null, // para save: object -> cie10
  observacion: null, // para save: string -> observacion
  observacionRecodificacion: null, // para saveRecodificacion: string -> observacion
  practica: null,
  cantidad: 1,
};

export const usePrescripcionElectronicaForm = ({ initData = null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageStatus, setMessageStatus] = useState({
    status: '',
    message: null,
  });

  /**
   * Evento que setea el objeto recetaData a su estado inicial
   */
  const onSendMessageEvent = ({ status = 'success', message = null }) => {
    setMessageStatus({ status, message });
  };

  /**
   * Evento que guarda la prescripción electrónica y retorna los nuevos valores guardados
   * @returns {Object} | null
   */
  const save = async (data) => {
    try {
      setIsLoading(true);
      //controlar si es una recodificacion  y si es asignar solo las practicas
      let body = {};
      let res = {};
      if (data.esRecodificacion) {
        body = {
          esRecodificacion: data.esRecodificacion,
          observacionRecodificacion: data.observacionRecodificacion,
          idPrescripcionElectronica: data?.prescripcionElectronica.idPrescripcionElectronica,
          practicas: data?.practicas?.map((item) => ({
            idPractica: item?.practica?.idPractica,
            cantidad: item?.cantidad,
          })),
        };
        res = await generarRecodificacionPrescripcionElectronica(body);
      } else {
        body = {
          idAfiliado: data?.afiliado?.idAfiliado,
          practicas: data?.practicas?.map((item) => ({
            idPractica: item?.practica?.idPractica,
            cantidad: item?.cantidad,
          })),
          fechaInicio: data?.fechaInicio,
          cie10: data?.cie10,
          cie10Secundario: data?.cie10Secundario,
          observacion: data?.observacion,
        };
        res = await generarPrescripcionElectronica(body);
      }

      if (res) {
        const afiliado = res.afiliado;
        afiliado.correo = afiliado.correo ? afiliado.correo : data.afiliado.correo;
        const urlReporte = await getReportePrescripcionElectronica({
          idPrescripcionElectronica: res.prescripcionElectronica.idPrescripcionElectronica,
        });

        const resultData = {
          ...res,
          urlReporte,
        };

        onSendMessageEvent({
          status: 'success',
          message: 'Prescripción electrónica generada.',
        });
        setIsLoading(false);

        return resultData;
      }
    } catch (e) {
      setIsLoading(false);
    }

    return null;
  };

  return {
    stateDataForm: initData || initDataForm,
    isLoading,
    messageStatus,
    save,
  };
};
