/**
 * @typedef {Object} UltimasPrescripcionesElectronicas
 * @property {integer} idAfiliado - Id del afiliado a consultar.
 * @property {integer} limit - Establece un limite para la lista y consulta.
 * @property {function} onSelectEvent - Función que se ejecuta cuando se selecciona una prescripción del historial.
 */

import { FormatListNumbered as FormatListNumberedIcon } from '@mui/icons-material';
import { Box, Fab, Menu, MenuItem, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { findPrescripcionesElectronicas } from '../../../services/Busquedas/PrescripcionesElectronicas';

/**
 * @param {UltimasPrescripcionesElectronicas} props
 */
export const UltimasPrescripcionesElectronicas = ({
  idAfiliado,
  limit,
  onSelectEvent,
  onTienePrescripcionesEvent,
  disabled,
}) => {
  const { options } = useUltimasPrescripcionesElectronicas({ idAfiliado, limit });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (onTienePrescripcionesEvent) {
      onTienePrescripcionesEvent(options?.length > 0 ? true : false);
    }
  }, [options]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHandleSelect = async (prescripcion) => {
    setAnchorEl(null);
    if (onSelectEvent) {
      const selected = {
        ...prescripcion,
        practicas: [
          ...prescripcion.practicas.map((i) => ({
            cantidad: i.cantidad || 1,
            practica: {
              codigo: i.codigoPractica,
              idPractica: i.idPractica,
              descripcion: i.descripcionPractica,
            },
          })),
        ],
        prescripcionElectronica: {
          ...prescripcion?.prescripcionElectronica,
          fechaEstado: prescripcion.historialEstado.fechaDesde,
        }, //cargar datos en findBy
      };

      onSelectEvent(selected);
    }
  };

  return (
    <>
      <Box
        sx={{
          height: { sm: '100%' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Tooltip title='Últimas prescripciones del afiliado seleccionado'>
          <Fab
            onClick={handleClick}
            size='small'
            color='default'
            disabled={disabled}
            sx={{ boxShadow: 'none' }}
            aria-controls={open ? 'account-menu-prescripciones' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <FormatListNumberedIcon fontSize='small' color='primary' />
          </Fab>
        </Tooltip>
      </Box>
      <Menu
        id='account-menu-prescripciones'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {options.length === 0 && <MenuItem disabled>Sin prescripciones generadas...</MenuItem>}
        {options.length > 0 &&
          options.map((prescripcionElectronica) => (
            <MenuItem
              key={prescripcionElectronica.prescripcionElectronica?.idPrescripcionElectronica}
              onClick={(e) => onHandleSelect(prescripcionElectronica)}
            >
              {prescripcionElectronica.tipoPracticaCategoria?.descripcion}
              {', '}(<strong>{prescripcionElectronica?.cie10?.codigo}</strong>){' '}
              {prescripcionElectronica?.cie10?.descripcion}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

/**
 *
 * @param {Object} { idAfiliado = {integer | null}, limit = {integer | null}}
 * @returns {Object} {options}
 */
const useUltimasPrescripcionesElectronicas = ({ idAfiliado = null, limit = 10 }) => {
  const [latestOption, setLatestOption] = useState([]);

  useEffect(() => {
    getLatestOptions();
  }, [idAfiliado]);

  const getLatestOptions = async () => {
    if (idAfiliado) {
      const result = await findPrescripcionesElectronicas({
        idAfiliado: idAfiliado,
        limite: limit,
      });
      if (result.length > 0) {
        setLatestOption(result);
      }
    }
  };

  return {
    options: latestOption,
  };
};
