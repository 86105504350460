import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { getPrestadores } from '../../services/Busquedas/Prestadores';

const PrestadorSelect = React.forwardRef((props, ref) => {
  const [value, setValue] = React.useState(null);
  const [prestadores, setPrestadores] = React.useState([]);

  useEffect(() => {
    const getPrestadoresList = async () => {
      const list = await getPrestadores();
      if (list?.length > 0) {
        setPrestadores(list);
        if (list.length === 1) setValuePrestador(list[0]);
        if (props.onCount !== undefined) {
          props.onCount(list.length);
        }
      }
    };

    if (prestadores.length === 0) {
      getPrestadoresList();
    }
  });

  const handleChange = (event, newValue) => {
    setValuePrestador(newValue);
  };

  const setValuePrestador = (value) => {
    setValue(value);
    props.onSelectPrestador(value);
  };

  useEffect(() => {
    if (props.value != null) {
      setValuePrestador(props.value);
    }
  }, [props.value]);

  return (
    <Autocomplete
      ref={ref}
      name={props.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(a) => `${a.nombre} (${a.matricula})`}
      onChange={handleChange}
      options={prestadores}
      value={value}
      disabled={props.disabled}
      loading={prestadores && prestadores.length === 0}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            value
              ? props.label
                ? props.label
                : 'Prestador'
              : 'Seleccioná un ' + (props.label ? props.label : 'prestador')
          }
          variant='standard'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {prestadores && prestadores.length === 0 ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});

export default PrestadorSelect;
