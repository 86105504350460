import HighlightOff from '@mui/icons-material/HighlightOff';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import LoadingAlert from '../../@iosper/components/LoadingButton/LoadingAlert';
import { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import backgroundQrFramework from '../../assets/images/svg/qr-framework.svg';

const OrdenDigitalQRMessageEvent = ({
  message = '',
  qrValue = null,
  prestador = {},
  status,
  open,
  onHandleCerrar = null,
}) => {
  const handleClose = () => {
    if (onHandleCerrar) {
      onHandleCerrar();
    }
  };

  useEffect(() => {}, [message]);

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={(e) => handleClose(e)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <Stack direction='column' spacing={1} justifyContent='center' alignItems='center' gap={2}>
            <Container>
              <Typography variant='h6' gutterBottom align='center'>
                Prestador
              </Typography>
              <Typography variant='subtitle1' gutterBottom align='center'>
                {prestador?.nombre} ({prestador?.matricula})
              </Typography>
            </Container>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <LoadingAlert result={status} defaultLoading loadingTime={1000} position='absolute' />
              <Box
                sx={{
                  backgroundImage: `url("${backgroundQrFramework}")`,
                  width: '170px',
                  height: '170px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  opacity: '0.08',
                  pointerEvents: 'none',
                }}
              >
                <QRCodeSVG value={JSON.stringify({ token: qrValue })} />
              </Box>
            </Box>
            <Container maxWidth='sm'>
              <Typography variant='subtitle2' align='center'>
                {message}
              </Typography>
            </Container>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            size='small'
            startIcon={<HighlightOff />}
            variant='contained'
            color='neutral'
            onClick={(e) => handleClose(e)}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrdenDigitalQRMessageEvent;
