import { Autocomplete, TextField, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { List } from 'react-virtualized';
import { getPrestadorAll } from '../../services/Busquedas/Prestadores';
import { useTheme } from '@emotion/react';
import { isMobile } from '../../@iosper/utils/DeviceDetector';

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, role, widthMax = 1000, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 36;
  // Para ver si entró desde mobile o web
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const esMobile = isMobile() || !matches;

  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={250}
          width={esMobile ? 350 : widthMax}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(props) => {
            return React.cloneElement(children[props.index], {
              style: props.style,
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

const PrestadorAutocompleteVirtualizado = React.forwardRef((props, ref) => {
  const [value, setValue] = React.useState(null);
  const [prestadores, setPrestadores] = React.useState([]);
  const { modoPrescriptor = false, label = 'Prestador', widthMax = 1000 } = props;

  useEffect(() => {
    const getPrestadoresList = async () => {
      const list = await getPrestadorAll({ modoPrescriptor: modoPrescriptor });
      if (list?.length > 0) {
        setPrestadores(list);
        if (list.length === 1) setValuePrestador(list[0]);
        if (props.onCount !== undefined) {
          props.onCount(list.length);
        }
      }
    };

    if (prestadores.length === 0) {
      getPrestadoresList();
    }
  });

  const handleChange = (event, newValue) => {
    setValuePrestador(newValue);
  };

  const setValuePrestador = (value) => {
    setValue(value);
    props.onSelectedPrestador(value);
  };

  useEffect(() => {
    if (props.value !== null) {
      setValuePrestador(props.value);
    }
  }, [props.value]);

  return (
    <Autocomplete
      id='prestador-virtualize'
      style={{ width: '100%' }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(a) => (a?.nombre ? `${a?.nombre} (${a?.matricula})` : '')}
      onChange={handleChange}
      value={value}
      loading={prestadores && prestadores.length === 0}
      ListboxComponent={React.forwardRef((listboxProps, listboxRef) => (
        <ListboxComponent {...listboxProps} widthMax={widthMax} ref={listboxRef} />
      ))}
      options={prestadores}
      filterOptions={(options, { inputValue }) => {
        // Divide la entrada del usuario en palabras
        const inputWords = inputValue?.toLowerCase().split(/\s+/).filter(Boolean);
        // Filtra las opciones
        return options?.filter((option) => {
          // Combina nombre y matrícula en un solo string para buscar en ambos campos
          const searchableText = `${option.nombre.toLowerCase()} ${option.matricula
            .toString()
            .toLowerCase()}`;
          // Verifica si todas las palabras de la entrada están presentes en el texto buscable
          return inputWords.every((word) => searchableText.includes(word));
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='standard'
          label={value ? `${label}` : `Seleccioná un ${label}`}
          fullWidth
        />
      )}
    />
  );
});

export default PrestadorAutocompleteVirtualizado;
