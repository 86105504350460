import { forwardRef, useEffect, useState } from 'react';
import { getTiposPracticas } from '../../services/Busquedas/Practicas';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

const useTipoPractica = () => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const getTiposPracticasList = async () => {
    setLoading(true);
    const tiposPracticas = await getTiposPracticas();
    setOptions(tiposPracticas || []);
    setLoading(false);
  };

  useEffect(() => {
    setOptions([]);
    getTiposPracticasList();
  }, []);

  return {
    options,
    loading,
  };
};

export const TipoPracticaAutocomplete = forwardRef(
  ({ value, onChangeOption, error, disabled }, ref) => {
    const { loading, options } = useTipoPractica();

    const handleChange = (newValue) => {
      if (onChangeOption) {
        onChangeOption(newValue);
      }
    };

    return (
      <Autocomplete
        value={value || null}
        isOptionEqualToValue={(option, value) =>
          option?.idGrupoInosPracticaCategoria === value?.idGrupoInosPracticaCategoria
        }
        getOptionLabel={(option) => `${option?.descripcion || ''}`}
        loading={loading}
        options={options}
        disabled={disabled}
        onChange={(_, newValue) => handleChange(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Tipo de Práctica'
            variant='standard'
            error={!!error}
            helperText={error ? error.message : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color='inherit' size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  },
);
