import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { ForwardToInbox, GetApp, OpenInNew, Print, UploadFile } from '@mui/icons-material';
import { QRCodeSVG } from 'qrcode.react';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ReactComponent as SuccessAlertAnimated } from '../../assets/images/svg/success-alert-animated-tres.svg';
import { isMobile } from '../../@iosper/utils/DeviceDetector';

const QrPreview = ({ showSuccessIcon = false, ...props }) => {
  /** funcionalidad de envio por correo electronico */
  useEffect(() => {
    setLoadingEnviar(props.loadingEnviar);
  }, [props.loadingEnviar]);

  useEffect(() => {
    setSuccessCorreo(props.successCorreo);
  }, [props.successCorreo]);

  const [showMail, setShowMail] = useState(props.showMail || false);
  const [correo, setCorreo] = useState(props.correo);
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [successCorreo, setSuccessCorreo] = useState('');

  /**------------------------------------------------ */

  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const esMobile = isMobile() || !matches;
  const showPrint =
    (props.showPrint !== undefined && props.showPrint === false) || !matches || isMobile()
      ? false
      : true;
  const showDownload = props.showDownload !== undefined ? props.showDownload : true;

  const handleDescargar = (event) => {
    event.preventDefault();
    const link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('href', props.urlReporte);
    link.setAttribute('download', props.nombreReporte + '.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImprimir = (e) => {
    e.preventDefault();
    var objFra = document.createElement('iframe');
    objFra.style.visibility = 'hidden';
    objFra.src = props.urlReporte;
    objFra.onload = function () {
      objFra.contentWindow.focus();
      objFra.contentWindow.print();
    };
    document.body.appendChild(objFra);
  };

  const handlePreview = () => {
    history.push({
      pathname: '/previsualizar',
      state: { url: props.urlReporte } || {}, // your data array of objects
    });
  };

  const handleEnviar = () => {
    props.onEnviarCorreo(correo);
  };

  const handleChangeCorreo = (event) => {
    setCorreo(event.target.value);
  };

  const handleBorrarMensaje = () => {
    setSuccessCorreo('');
  };

  return (
    <Grid container direction='column' justifyContent='center' spacing={1} sx={{ mb: 3 }}>
      {showPrint && (
        <Grid item>
          <Box display='flex' justifyContent='center' alignItems='center' gap={2}>
            <QRCodeSVG value={props.qrValue} />
            {showSuccessIcon && (
              <Box>
                <SuccessAlertAnimated
                  className={'animated'}
                  style={{
                    height: '80px',
                    width: '80px',
                  }}
                />
              </Box>
            )}
          </Box>
        </Grid>
      )}
      {(showDownload || showPrint) && (
        <Grid
          item
          container
          spacing={2}
          sx={{
            marginTop: '5px',
            flexWrap: 'wrap',
            flexDirection: esMobile ? 'column' : 'row',
          }}
        >
          {showMail && (
            <Grid
              item
              xs={12}
              md={5}
              sx={{ order: esMobile ? 2 : 1, display: 'flex', flexGrow: 1 }}
            >
              <FormControl fullWidth disabled={loadingEnviar}>
                <TextField
                  id='enviar-correo'
                  label='Enviar por correo'
                  onChange={handleChangeCorreo}
                  defaultValue={props.correo}
                  fullWidth
                  focused
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='large button group'
                          onClick={handleEnviar}
                          color='primary'
                          disabled={
                            loadingEnviar || correo === null || correo === '' ? true : false
                          }
                        >
                          {loadingEnviar ? <UploadFile /> : <ForwardToInbox />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} md={7} sx={{ order: esMobile ? 1 : 2, display: 'flex', flexGrow: 1 }}>
            <ButtonGroup
              fullWidth
              aria-label='large button group'
              orientation={esMobile ? 'vertical' : 'horizontal'}
              sx={{ justifyContent: 'center' }}
            >
              {showPrint && (
                <Button
                  color='primary'
                  onClick={handleImprimir}
                  sx={{ width: esMobile ? '100%' : '33%' }}
                >
                  Imprimir &nbsp;&nbsp; <Print />
                </Button>
              )}
              {showDownload && (
                <Button onClick={handleDescargar} sx={{ width: esMobile ? '100%' : '33%' }}>
                  Descargar &nbsp;&nbsp; <GetApp />
                </Button>
              )}
              {showPrint && (
                <Button
                  color='primary'
                  onClick={handlePreview}
                  sx={{ width: esMobile ? '100%' : '33%' }}
                >
                  Previsualizar &nbsp;&nbsp; <OpenInNew />
                </Button>
              )}
            </ButtonGroup>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default QrPreview;
