import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { forwardRef } from 'react';

export const CANTIDAD_RECETAS_OPTIONS = [{ cantidad: 1 }, { cantidad: 2 }, { cantidad: 3 }];
export const CANTIDAD_RECETAS_CRONICAS_OPTIONS = [
  { cantidad: 1 },
  { cantidad: 2 },
  { cantidad: 3 },
  { cantidad: 4 },
  { cantidad: 5 },
  { cantidad: 6 },
];

const CantidadRecetasCombo = forwardRef(
  (
    { value, disabled = false, isCronicoEspecial = false, onSelectCantidadRecetas, ...props },
    ref,
  ) => {
    const handleChange = (event, newValue) => {
      if (onSelectCantidadRecetas) {
        onSelectCantidadRecetas(newValue);
      }
    };

    return (
      <Autocomplete
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option.cantidad === value.cantidad}
        getOptionLabel={(a) => `${a.cantidad}`}
        onChange={handleChange}
        value={value}
        options={isCronicoEspecial ? CANTIDAD_RECETAS_CRONICAS_OPTIONS : CANTIDAD_RECETAS_OPTIONS}
        loading={
          CANTIDAD_RECETAS_OPTIONS &&
          CANTIDAD_RECETAS_OPTIONS.length === 0 &&
          CANTIDAD_RECETAS_CRONICAS_OPTIONS &&
          CANTIDAD_RECETAS_CRONICAS_OPTIONS.length === 0
        }
        renderInput={(params) => (
          <TextField
            id='textCantidadRecetas'
            {...params}
            label='Cant. recetas'
            variant='standard'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {CANTIDAD_RECETAS_OPTIONS && CANTIDAD_RECETAS_OPTIONS.length === 0 && (
                    <CircularProgress color='inherit' size={20} />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  },
);

export default CantidadRecetasCombo;
