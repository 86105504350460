import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  ListItem,
  ListItemText,
  Box,
  ToggleButton,
  Collapse,
  List,
  CardContent,
  Stack,
  Menu,
  MenuItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  OpenInNew,
  PictureAsPdfOutlined,
  GetApp,
  Delete,
  ForwardToInbox,
  MoreVert,
  Medication,
} from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useWidth } from '../../../@iosper/utils/UseWidth';
import { useHistory } from 'react-router-dom';
import { getReportePrescripcionElectronica } from '../../../services/PrescripcionElectronica/PrescripcionElectronica';
//import AnularPrescripcionElectronicaPopUp from '../Receta/AnularPrescripcionElectronicaPopUp';
//import EnviarPrescripcionElectronicaPopUp from '../Receta/EnviarPrescripcionElectronicaPopUp';
import StyledDataGridRecetas from '../../Receta/StyledDataGridRecetas';
import { findPrescripcionElectronica } from '../../../services/PrescripcionElectronica/PrescripcionElectronica';
import CustomizedSnackbars from '../../Notifications/SnackBar';
import EnviarPrescripcionElectronicaPopUp from '../EnviarPrescripcionElectronicaPopUp';
import AnularPrescripcionElectronicaPopUp from '../AnularPrescripcionElectronicaPopUp';
import StyledDataGridPrescripciones from '../hooks/StyledDataGridPrescripciones';

const DataCollapseDrawer = (props) => {
  const { prescripcionElectronica, esProfesionalBioquimico } = props;
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDescargar = async (filename, idPrescripcionElectronica) => {
    try {
      let idsPrescripcionesElectronicas = [];
      idsPrescripcionesElectronicas.push(idPrescripcionElectronica);
      const urlReporte = await getReportePrescripcionElectronica({
        idsPrescripcionesElectronicas: idsPrescripcionesElectronicas,
      });
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', urlReporte);
      link.setAttribute('download', 'Prescripción Electrónica ' + filename + '.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };
  const handleEnviar = (prescripcionElectronica) => {
    props.onEnviar(prescripcionElectronica);
    handleClose();
  };

  const handleAnular = (prescripcionElectronica) => {
    props.onAnular(prescripcionElectronica);
    handleClose();
  };

  const handleGenerar = (prescripcionElectronica) => {
    props.onGenerar(prescripcionElectronica);
    handleClose();
  };
  const handleRecodificar = (prescripcionElectronica) => {
    props.onRecodificar(prescripcionElectronica);
    handleClose();
  };

  const handlePreview = (prescripcionElectronica) => {
    props.onPreview(prescripcionElectronica);
    handleClose();
  };

  return (
    <Box sx={{ width: '100%', marginLeft: -1, paddingLeft: -2, marginRight: -5, paddingRight: -5 }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0, p: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          sx={{
            marginRight: '-30px',
            marginLeft: '-20px',
            marginBottom: '-8px',
            marginTop: '-8px',
          }}
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={0}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <List sx={{ py: -5, px: 0, marginBottom: '0px', marginTop: '-10px' }}>
            <ListItem sx={{ py: 0, px: -2 }}>
              <ListItemText
                primary={`Nro.: ${prescripcionElectronica.numero}${
                  prescripcionElectronica.periodo ? ` (${prescripcionElectronica.periodo})` : ''
                } - ${prescripcionElectronica.afiliado || ''}`}
                secondary={
                  <>
                    <Typography
                      component='span'
                      variant='caption'
                      fontWeight='bold'
                      sx={{ textTransform: 'none' }}
                    >
                      {prescripcionElectronica.estado}
                    </Typography>
                    {' - '}
                    <Typography component='span' variant='caption'>
                      {prescripcionElectronica.fechaPrescripcion || ''}
                    </Typography>
                  </>
                }
                primaryTypographyProps={{
                  textTransform: 'none',
                  fontSize: '16px',
                }}
              />
            </ListItem>
          </List>
          <List sx={{ p: 0 }}>
            <ListItem sx={{ justifyContent: 'flex-end', p: 0 }}>
              <ListItemButton
                aria-label='more'
                sx={{ p: 0 }}
                id='long-button'
                aria-controls={openAction ? 'long-menu' : undefined}
                aria-expanded={openAction ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <MoreVert />
              </ListItemButton>
              <Menu
                id='long-menu'
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 50 * 4.5,
                    width: '17ch',
                  },
                }}
              >
                <MenuItem
                  key={'descargar'}
                  onClick={() => {
                    handleDescargar(prescripcionElectronica.afiliado, prescripcionElectronica.id);
                  }}
                  disabled={
                    prescripcionElectronica.codigoEstado !== '1' &&
                    prescripcionElectronica.codigoEstado !== '3'
                  }
                >
                  <GridActionsCellItem icon={<GetApp />} title='Reporte PDF' label='Descargar' />
                  Descargar
                </MenuItem>
                <MenuItem
                  key={'anular'}
                  onClick={() => {
                    handleAnular(prescripcionElectronica);
                  }}
                  disabled={
                    prescripcionElectronica.codigoEstado !== '1' &&
                    prescripcionElectronica.codigoEstado !== '3'
                  }
                >
                  <GridActionsCellItem icon={<Delete />} title='Anular' label='Anular' />
                  Anular
                </MenuItem>
                <MenuItem
                  key={'enviar'}
                  onClick={() => {
                    handleEnviar(prescripcionElectronica);
                  }}
                  disabled={
                    prescripcionElectronica.codigoEstado !== '1' &&
                    prescripcionElectronica.codigoEstado !== '3'
                  }
                >
                  <GridActionsCellItem
                    icon={<ForwardToInbox />}
                    disabled={
                      prescripcionElectronica.codigoEstado !== '1' &&
                      prescripcionElectronica.codigoEstado !== '3'
                    }
                    title='Enviar'
                    label='Enviar'
                  />
                  Enviar
                </MenuItem>
                <MenuItem
                  key={'preview'}
                  onClick={() => {
                    handlePreview(prescripcionElectronica);
                  }}
                >
                  <GridActionsCellItem
                    icon={<ForwardToInbox />}
                    title='Ver Prescripción Práctica'
                    label='Ver Prescripción Práctica'
                  />
                  Ver Prescripción
                </MenuItem>

                {esProfesionalBioquimico && (
                  <MenuItem
                    key={'recodificar'}
                    onClick={() => {
                      handleRecodificar(prescripcionElectronica);
                    }}
                  >
                    <GridActionsCellItem
                      icon={<Medication />}
                      /*disabled={prescripcionElectronica.codigoEstado !== '1'}*/
                      title='Recodificar'
                      label='Recodificar'
                    />
                    Recodificar
                  </MenuItem>
                )}

                {!esProfesionalBioquimico && (
                  <MenuItem
                    key={'generar'}
                    onClick={() => {
                      handleGenerar(prescripcionElectronica);
                    }}
                  >
                    <GridActionsCellItem
                      icon={<Medication />}
                      disabled={
                        prescripcionElectronica.codigoEstado !== '1' &&
                        prescripcionElectronica.codigoEstado !== '3'
                      }
                      title='Repetir prescripción'
                      label='Repetir prescripción'
                    />
                    Repetir tratamiento
                  </MenuItem>
                )}
              </Menu>
            </ListItem>
          </List>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 0 }}>
          <List>
            {(prescripcionElectronica.codigoEstado === '1' ||
              prescripcionElectronica.codigoEstado === '3') && (
              <ListItem
                sx={{
                  paddingTop: -2,
                  marginTop: -2,
                  marginBottom: -1,
                  py: 0,
                }}
              >
                <ListItemText
                  secondary={
                    <>
                      <Typography component='span' variant='caption' fontWeight='bold'>
                        Vencimiento:
                      </Typography>{' '}
                      <Typography component='span' variant='caption'>
                        {prescripcionElectronica.vencimiento}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            )}

            <ListItem
              sx={{
                paddingTop: -1,
                marginTop: -1,
                py: 0,
              }}
            >
              <ListItemText
                secondary={
                  <>
                    <Typography component='span' variant='caption' fontWeight='bold'>
                      Diagnóstico:
                    </Typography>{' '}
                    <Typography component='span' variant='caption'>
                      {prescripcionElectronica.diagnostico}
                      {prescripcionElectronica.diagnosticoSecundario &&
                        `, ${prescripcionElectronica.diagnosticoSecundario}`}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <ListItem
              sx={{
                paddingTop: -1,
                marginTop: -1,
                py: 0,
              }}
            >
              <ListItemText
                secondary={
                  <>
                    <Typography component='span' variant='caption' fontWeight='bold'>
                      Prácticas:
                    </Typography>{' '}
                    <Typography component='span' variant='caption'>
                      {prescripcionElectronica.practicas}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const PrescripcionElectronicaTableResult = (props) => {
  const history = useHistory();
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up('sm'));
  //const esMobile = matches ? false : true;
  const [success, setSuccess] = useState('');
  const screenWidth = useWidth();

  const [prescripcionElectronica, setRecetaElecctronica] = useState(null);
  const [onOpenAnular, setOpenAnular] = useState(null);
  const [onOpenEnviar, setOpenEnviar] = useState(null);
  const [loadingResults, setLoadingResults] = useState(null);

  useEffect(() => {
    if (props.prescripcionesElectronicas.length > 1) {
      setLoadingResults(false);
    }
  }, [props.prescripcionesElectronicas]);

  const handleAnular = (prescripcionElectronica) => {
    setRecetaElecctronica(prescripcionElectronica);
    setOpenAnular(true);
  };
  const handleEnviar = (prescripcionElectronica) => {
    setRecetaElecctronica(prescripcionElectronica);
    setOpenEnviar(true);
  };

  const handleGenerar = async (prescripcionElectronica) => {
    try {
      if (prescripcionElectronica.resume.prescripcionElectronica.idPrescripcionElectronica) {
        history.push({
          pathname: `/generar-prescripcion-electronica/${prescripcionElectronica.idPrescripcionElectronica}`,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleRecodificar = async (prescripcionElectronica) => {
    try {
      if (prescripcionElectronica.resume.prescripcionElectronica.idPrescripcionElectronica) {
        history.push({
          pathname: `/generar-prescripcion-electronica/${prescripcionElectronica.idPrescripcionElectronica}/true`,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAnulada = async (anulada) => {
    setSuccess('La prescripción práctica ha sido anulada correctamente');
    setLoadingResults(true);
    setRecetaElecctronica(null);
    setOpenAnular(false);
    if (anulada) {
      props.onRefresh();
    }
  };

  const handleEnviada = async (enviada) => {
    setSuccess('La prescripción electrónica de prácticas ha sido enviada correctamente');
    setRecetaElecctronica(null);
    setOpenEnviar(false);
  };

  const pdfClic = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        handlePdf(id);
      });
    },
    [],
  );
  const previewClic = React.useCallback(
    (row) => () => {
      setTimeout(() => {
        handlePreview(row);
      });
    },
    [],
  );

  const anularClic = React.useCallback(
    (value) => () => {
      handleAnular(value);
    },
    [],
  );

  const enviarClic = React.useCallback(
    (value) => () => {
      handleEnviar(value);
    },
    [],
  );

  const generarClic = React.useCallback(
    (value) => () => {
      handleGenerar(value);
    },
    [],
  );
  const recodificarClic = React.useCallback(
    (value) => () => {
      handleRecodificar(value);
    },
    [],
  );

  const handlePdf = async (idPrescripcionElectronica) => {
    try {
      const urlReporte = await getReportePrescripcionElectronica({
        idPrescripcionElectronica: idPrescripcionElectronica,
      });
      history.push({
        pathname: '/previsualizar',
        state:
          {
            url: urlReporte,
            datosBack: {
              pathnameBack: '/consultar-prescripciones-electronicas',
              prescripcionesElectronicas: props.prescripcionesElectronicas,
            },
          } || {}, // your data array of objects
      });
    } catch (e) {
      console.log(e);
    }
  };
  const handlePreview = async (row) => {
    try {
      let prescripcion = {};
      if (props.prescripcionesElectronicas?.length >= 1) {
        prescripcion = props.prescripcionesElectronicas[row.index];
      } else {
        const result = await findPrescripcionElectronica(row.id);
        if (result?.length === 1) {
          prescripcion = result[0];
        }
      }

      //armo objeto apto para reutilizar vista de prescripcion-generada
      const consulta = {
        ...prescripcion,
        esConsulta: true,
        afiliado: {
          nombre: prescripcion.personaFisicaAfiliado?.nombre,
          apellido: prescripcion.personaFisicaAfiliado?.apellido,
          idAfiliado: prescripcion.afiliado.idAfiliado,
          numeroDocumento: prescripcion.personaFisicaAfiliado?.numeroDocumentoString,
          tipoDocumento: prescripcion.tipoDocumentoAfiliado?.descripcion,
        },
        profesional: {
          ...prescripcion.profesional,
          descripcion: `${
            (prescripcion?.personaFisicaProfesional?.apellido,
            prescripcion?.personaFisicaProfesional?.nombre)
          }`,
        },
        prescripcionElectronica: {
          ...prescripcion?.prescripcionElectronica,
          fechaEstado: prescripcion.historialEstado.fechaDesde,
        },
        cantidadRecetas: prescripcion.estado, //cargar datos en findBy
        descripcionIds: '', //cargar datos en findBy
      };

      history.push('/prescripcion-electronica-generada', {
        from: history.location,
        prescripcionElectronica: consulta,
        datosBack: {
          pathnameBack: '/consultar-prescripciones-electronicas',
          prescripcionesElectronicas: props.prescripcionesElectronicas,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const mapPrescripcionElectronicaToRow = (a, index) => {
    return {
      id: a.prescripcionElectronica.idPrescripcionElectronica,
      index: index,
      numero: a.prescripcionElectronica.idPrescripcionElectronica,
      tipoPracticaCategoria: a.tipoPracticaCategoria.descripcion,
      resume: a,
      idPrescripcionElectronica: a.prescripcionElectronica.idPrescripcionElectronica,
      estado: a.estado.descripcion,
      codigoEstado: a.estado.codigo,
      fechaEstado: a.historialEstado.fechaDesde,
      fechaPrescripcion: a.prescripcionElectronica.fechaPrescripcion,
      vencimiento: a.prescripcionElectronica.fechaVencimiento,
      afiliado: `${a.personaFisicaAfiliado.apellido}, ${a.personaFisicaAfiliado.nombre}`,
      dni: a.personaFisicaAfiliado.numeroDocumentoString,
      correo: a.afiliado.correo,
      prescriptor: `${a.personaFisicaProfesional.apellido}, ${a.personaFisicaProfesional.nombre}`,
      diagnostico: `(${a.cie10.codigo}) ${a.cie10.descripcion}`,
      diagnosticoSecundario: a.cie10Secundario?.codigo
        ? `(${a.cie10Secundario.codigo}) ${a.cie10Secundario.descripcion}`
        : '',
      practicas: a.practicas
        .map((practica) => `${practica.codigoPractica} x${practica.cantidad}`)
        .join(', '),
    };
  };

  const columns = React.useMemo(
    () => [
      {
        field: 'desplegable',
        headerName: 'Prescripciones electrónicas:',
        flex: 1,
        renderCell: (a) => (
          <DataCollapseDrawer
            esProfesionalBioquimico={props.esProfesionalBioquimico || false}
            prescripcionElectronica={a.row}
            onAnular={(e) => {
              handleAnular(e);
            }}
            onEnviar={(e) => {
              handleEnviar(e);
            }}
            onRecodificar={(e) => {
              handleRecodificar(e);
            }}
            onGenerar={(e) => {
              handleGenerar(e);
            }}
            onPreview={(e) => {
              handlePreview(e);
            }}
          />
        ),
      },
      { field: 'numero', headerName: 'Nro.', width: 80 },
      { field: 'afiliado', headerName: 'Afiliado', flex: 0.08 },
      { field: 'estado', headerName: 'Estado', width: 85 },
      { field: 'tipoPracticaCategoria', headerName: 'Tipo', width: 110 },
      { field: 'fechaPrescripcion', headerName: 'Prescripción', width: 100 },
      { field: 'vencimiento', headerName: 'Vencimiento', width: 100 },
      { field: 'diagnostico', headerName: 'Diagnóstico Principal', flex: 0.1 },
      { field: 'diagnosticoSecundario', headerName: 'Diagnóstico Secundario', flex: 0.1 },
      { field: 'practicas', headerName: 'Prácticas', flex: 0.1 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Acciones',
        width: 80,
        getActions: (params) => {
          const itemsMenu = [
            <GridActionsCellItem
              icon={<Delete />}
              label='Anular'
              disabled={params.row.codigoEstado !== '1' && params.row.codigoEstado !== '3'}
              onClick={anularClic(params.row)}
            />,
            <GridActionsCellItem
              icon={<PictureAsPdfOutlined />}
              label='Reporte PDF'
              disabled={params.row.codigoEstado !== '1' && params.row.codigoEstado !== '3'}
              onClick={pdfClic(params.row.id)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<OpenInNew />}
              label='Ver Prescripción'
              onClick={previewClic(params.row)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<ForwardToInbox />}
              label='Enviar'
              disabled={params.row.codigoEstado !== '1' && params.row.codigoEstado !== '3'}
              onClick={enviarClic(params.row)}
              showInMenu
            />,
          ];

          if (!props.esProfesionalBioquimico) {
            itemsMenu.push(
              <GridActionsCellItem
                icon={<Medication />}
                label='Repetir tratamiento'
                onClick={generarClic(params.row)}
                showInMenu
              />,
            );
          }

          if (props.esProfesionalBioquimico) {
            itemsMenu.push(
              <GridActionsCellItem
                icon={<Medication />}
                label='Recodificar'
                onClick={recodificarClic(params.row)}
                showInMenu
              />,
            );
          }

          return itemsMenu;
        },
      },
    ],
    [previewClic, anularClic, enviarClic, generarClic],
  );

  const dataGridPrescripciones = props.prescripcionesElectronicas && (
    <Grid
      container
      direction='column'
      justifyContent='space-between'
      spacing={1}
      sx={{ paddingTop: '-10px', marginTop: '-30px' }}
    >
      <Grid item>
        <div style={{ height: '60vh', width: '100%' }}>
          <StyledDataGridPrescripciones
            getRowClassName={(params) => `super-app-theme--${params.row.codigoEstado}`}
            sx={{
              border: 0,
              '& .MuiDataGrid-cell': {
                py: '5px',
              },
            }}
            columns={columns}
            loading={loadingResults}
            rows={props.prescripcionesElectronicas.map((a, index) =>
              mapPrescripcionElectronicaToRow(a, index),
            )} //
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}
            components={{
              NoRowsOverlay: () => (
                <Stack height='100%' alignItems='center' justifyContent='center'>
                  Sin Prescripciones de Práctica.
                </Stack>
              ),
            }}
            columnVisibilityModel={{
              desplegable: screenWidth === 'xs',
              numero: screenWidth !== 'xs',
              afiliado: screenWidth !== 'xs',
              tipoPracticaCategoria: screenWidth !== 'xs',
              diagnostico: screenWidth !== 'xs',
              diagnosticoSecundario: screenWidth !== 'xs',
              fechaPrescripcion: screenWidth !== 'xs',
              practicas: screenWidth !== 'xs',
              vencimiento: screenWidth !== 'xs',
              estado: screenWidth !== 'xs',
              actions: screenWidth !== 'xs',
            }}
            pagination
            autoPageSize
            disableColumnMenu
          />
        </div>
      </Grid>
    </Grid>
  );
  const handleBorrarMensaje = () => {
    setSuccess('');
  };

  return (
    <Grid container spacing={2} sx={{ paddingTop: '-10px', marginTop: '-10px' }}>
      <Grid item xs={12} sx={{ paddingTop: '-20px', marginTop: '-10px' }}>
        <Card sx={{ width: '100%', paddingTop: '-20px', marginTop: '-10px' }}>
          <CardContent>{dataGridPrescripciones}</CardContent>
        </Card>
      </Grid>
      {onOpenAnular ? (
        <AnularPrescripcionElectronicaPopUp
          open={onOpenAnular}
          prescripcionElectronica={prescripcionElectronica}
          setPrescripcionAnulada={handleAnulada}
          onCancelar={() => setOpenAnular(false)}
        />
      ) : null}
      {onOpenEnviar ? (
        <EnviarPrescripcionElectronicaPopUp
          open={onOpenEnviar}
          prescripcionElectronica={prescripcionElectronica}
          setPrescripcionEnviada={handleEnviada}
          onCancelar={() => setOpenEnviar(false)}
        />
      ) : null}

      <CustomizedSnackbars
        open={success}
        autoHideDuration={8000}
        severity='success'
        message={success}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default PrescripcionElectronicaTableResult;
