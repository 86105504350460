import { useEffect, useState } from 'react';
import { findPrescripcionElectronica } from '../../../services/PrescripcionElectronica/PrescripcionElectronica';

export const usePrescripcionElectronica = ({
  idPrescripcionElectronica = null,
  prescripcionElectronicaDataForm = null,
}) => {
  const [stateData, setStateData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [messageStatus, setMessageStatus] = useState({
    status: '',
    message: null,
  });

  /**
   * Evento que setea el objeto messageStatus a su estado inicial
   */
  const onSendMessageEvent = ({ status = 'success', message = null }) => {
    setMessageStatus({ status, message });
  };

  useEffect(() => {
    if (idPrescripcionElectronica) {
      getPrescripcionElectronica(idPrescripcionElectronica);
    } else {
      setStateData(null);
    }

    if (prescripcionElectronicaDataForm?.datosBack?.prescripcionData) {
      setStateData(prescripcionElectronicaDataForm.datosBack.prescripcionData);
    }
  }, [idPrescripcionElectronica, prescripcionElectronicaDataForm]);

  const getPrescripcionElectronica = async (id) => {
    try {
      const result = await findPrescripcionElectronica(id);
      const [dia, mes, anio] = result.prescripcionElectronica.fechaInicio.split(' ')[0].split('/');
      const fechaFormateada = `${anio}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
      const resultLimpio = {
        ...result,
        afiliado: result.afiliado,
        tipoPractica: result.tipoPracticaCategoria,
        practicas: [
          ...result.practicas.map((i) => ({
            cantidad: i.cantidad || 1,
            practica: {
              codigo: i.codigoPractica,
              idPractica: i.idPractica,
              descripcion: i.descripcionPractica,
              tieneConvenio: i.tieneConvenio,
            },
          })),
        ],
        fechaInicio: fechaFormateada,
        cie10: result.cie10?.id || result.cie10?.idCie10 ? result.cie10 : null,
        cie10Secundario:
          result.cie10Secundario?.id || result.cie10Secundario?.idCie10
            ? result.cie10Secundario
            : null,
        observacion: result.prescripcionElectronica.observacion,
      };

      setStateData(resultLimpio);
    } catch (e) {
      console.log(e);
    }
  };

  return {
    stateData,
    messageStatus,
    onSendMessageEvent,
    isLoading,
  };
};
