import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { Box, Button, FormControl, Grid, Stack, TextField } from '@mui/material';
import { ForwardToInbox, HighlightOff } from '@mui/icons-material';
import LoadingButton from '../../@iosper/components/LoadingButton';
import { enviarPrescripcionElectronica } from '../../services/PrescripcionElectronica/PrescripcionElectronica';
import ModalBase from '../Controls/ModalBase';

const EnviarPrescripcionElectronicaPopUp = (props) => {
  const [datos, setDatos] = useState({
    idPrescripcionElectronica: props.prescripcionElectronica.id,
    correo: props.prescripcionElectronica?.correo,
  });

  const [loadingEnviar, setLoadingEnviar] = useState(false);

  useEffect(() => {}, []);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const onEnviarPrescripcion = async (data, event) => {
    event.preventDefault();
    setLoadingEnviar(true);
    try {
      setLoadingEnviar(true);
      const res = await enviarPrescripcionElectronica(datos);
      if (res) {
        setLoadingEnviar(false);
        props.setPrescripcionEnviada(true);
      }
    } catch (e) {
      setLoadingEnviar(false);
    }
  };

  const handleClose = () => {
    props.onCancelar();
  };

  const DrawerForm = (
    <form onSubmit={handleSubmit(onEnviarPrescripcion)}>
      <FormControl fullWidth={true} variant='outlined' margin='normal'>
        <TextField
          multiline
          maxRows={4}
          {...register('correo', {
            required: {
              value: true,
              message: 'El campo es requerido',
            },
            pattern: {
              value:
                '^(([^<>()\\[]\\.,;:s@"]+(.[^<>()\\[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/',
              message: 'El formato no es correcto (nombre@dominio.com)',
            },
          })}
          variant='standard'
          fullWidth
          name='correo'
          label='Ingresá el correo'
          id='Correo'
          value={datos.correo}
          onChange={(newValue) => {
            setDatos({ ...datos, correo: newValue.target.value });
          }}
        />
        {errors.correo && <p style={{ color: 'red' }}>{errors.correo.message}</p>}
      </FormControl>

      <Box sx={{ width: '100%', padding: '10px' }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2 }}
          justifyContent={{ xs: 'center', sm: 'flex-end' }}
        >
          <Button
            variant='contained'
            startIcon={<HighlightOff />}
            color='neutral'
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <LoadingButton
            fullWidth={true}
            variant='contained'
            size='medium'
            color='primary'
            startIcon={<ForwardToInbox />}
            type='submit'
            loading={loadingEnviar ? 'show' : 'hide'}
            content={'Enviar'}
          />
        </Stack>
      </Box>
    </form>
  );

  const popUpEnviar = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography gutterBottom variant='h6'>
          Enviar prescripción electrónica al siguiente correo:
        </Typography>
        {DrawerForm}
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <ModalBase
        fullWidth={false}
        open={true}
        content={popUpEnviar}
        title='Enviar Prescripcion'
        setPrescripcionEnviada={props.setPrescripcionEnviada}
      />
    </Box>
  );
};

export default EnviarPrescripcionElectronicaPopUp;
