import { useEffect, useState } from 'react';
import {
  findRecetasElectronicas,
  SOLO_AMBULATORIOS,
  SOLO_PLANES_ESPECIALES,
} from '../../../services/Busquedas/RecetasElectronicas';

const dataDefaultValues = {
  idAfiliado: null,
  documento: '',
  fechaDesde: '',
  fechaHasta: '',
  tipoReceta: '',
  soloAmbulatorios: false,
  soloPlanesEspeciales: false,
};

const getTipoRecetaString = ({ soloAmbulatorios = false, soloPlanesEspeciales = false }) => {
  if (soloAmbulatorios) {
    return SOLO_AMBULATORIOS;
  }
  if (soloPlanesEspeciales) {
    return SOLO_PLANES_ESPECIALES;
  }
  return '';
};

export const useBuscarReceta = ({ dataInit = dataDefaultValues, idAfiliado, locationState }) => {
  const defaultData = {
    ...dataInit,
    idAfiliado: idAfiliado || dataInit.idAfiliado,
    tipoReceta: getTipoRecetaString(locationState?.tipoReceta || {}),
    soloAmbulatorios: locationState?.tipoReceta?.soloAmbulatorios || false,
    soloPlanesEspeciales: locationState?.tipoReceta?.soloPlanesEspeciales || false,
  };
  const [dataForm, setDataForm] = useState(defaultData);
  const [recetasElectronicas, setRecetasElectronicas] = useState([]);
  const [messageStatus, setMessageStatus] = useState({
    status: '',
    message: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [isFromNuevaReceta, setIsFromNuevaReceta] = useState(false);
  const [documento, setDocumento] = useState(null);

  useEffect(() => {
    if (
      recetasElectronicas.length > 0 &&
      recetasElectronicas[0].personaFisicaAfiliado?.numeroDocumento
    ) {
      setDocumento(recetasElectronicas[0].personaFisicaAfiliado.numeroDocumento);
    }
  }, [recetasElectronicas]);

  useEffect(() => {
    if (idAfiliado) {
      setIsFromNuevaReceta(true);

      try {
        buscarRecetasElectronicas(dataForm);
      } catch (e) {
        setIsLoading(false);
      }
    }

    if (locationState?.recetasElectronicas?.length) {
      setShowForm(false);
      setRecetasElectronicas(locationState.recetasElectronicas);
    }
  }, []);

  /**
   * Metodo que busca las recetas electronicas segun parametros del form
   * @param {object} data
   */
  const buscarRecetasElectronicas = async (data) => {
    try {
      setShowForm(false);
      const result = await findRecetasElectronicas(data);
      setRecetasElectronicas(result);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Metodo que busca las recetas
   * @param {object} data
   */
  const handlerFindRecetasSubmit = async (data) => {
    setRecetasElectronicas([]);
    setIsLoading(true);
    try {
      const result = await findRecetasElectronicas({
        ...data,
        soloAmbulatorios: data.tipoReceta === SOLO_AMBULATORIOS,
        soloPlanesEspeciales: data.tipoReceta === SOLO_PLANES_ESPECIALES,
      });
      setRecetasElectronicas(result);

      if (!result || result.length < 1) {
        setMessageStatus({
          status: 'info',
          message: 'Busqueda sin resultados, intente con otros parámetros.',
        });
      } else {
        setShowForm(false);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  /**
   * Limpia el listado de recetas y muestra el formulario par nueva busqueda
   */
  const onHandlerBackToForm = () => {
    setShowForm(true);
  };

  /**
   * Carga y muestra un mensaje
   */
  const setAndShowMessage = ({ status = '', message = '' }) => {
    setMessageStatus({ status, message });
  };

  /**
   * Limpia los mensajes
   */
  const onHandlerCleanMessage = () => {
    setMessageStatus({ status: '', message: '' });
  };

  const updateIdAfiliado = (newIdAfiliado) => {
    setDataForm((prevData) => ({
      ...prevData,
      idAfiliado: newIdAfiliado,
    }));
  };

  return {
    dataForm,
    recetasElectronicas,
    messageStatus,
    isLoading,
    showForm,
    isFromNuevaReceta,
    setAndShowMessage,
    onHandlerBackToForm,
    onHandlerCleanMessage,
    handlerFindRecetasSubmit,
    /**updateIdAfiliado, documento,setDocumento : usados para actualizar el formulario dependiendo si viene de nueva receta o no */
    updateIdAfiliado,
    documento,
    setDocumento,
  };
};
