import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Grid, Stack } from '@mui/material';
import { getReimprimirOrden } from '../../services/Seo/Orden';
import LoadingButton from '../../@iosper/components/LoadingButton';
import HighlightOff from '@mui/icons-material/HighlightOff';
import Print from '@mui/icons-material/Print';
import { PreviewBox } from '../Common/PreviewBox';
import { PreviewField } from '../Common/PreviewField';

const OrdenParaReimprimir = (props) => {
  const [datosOrden, setDatosOrden] = useState({});
  const [ordenGenerada, setOrdenGenerada] = useState(false);
  const [loadingImprimirOrden, setLoadingImprimirOrden] = useState(false);
  const { handlerReimprimir } = props;

  useEffect(() => {
    setDatosOrden(props?.datosOrden);

    return function cleanup() {
      setDatosOrden({});
    };
  }, [props.datosOrden]);

  if (!datosOrden) {
    return null;
  }

  const handleClose = () => {
    setLoadingImprimirOrden(false);
    setOrdenGenerada(false);
    props.setOpen(false);
    setDatosOrden({});
  };

  const handlePrint = async (event) => {
    setLoadingImprimirOrden(true);
    event.preventDefault();
    if (
      datosOrden !== null &&
      datosOrden.orden.numeroOrdenSinFormato !== undefined &&
      datosOrden.orden.numeroOrdenSinFormato !== null
    ) {
      try {
        const res = await getReimprimirOrden({
          numeroOrden: datosOrden.orden.numeroOrdenSinFormato,
        });
        setLoadingImprimirOrden(false);
        handlerReimprimir(datosOrden, res);
        handleClose();
      } catch (e) {
        setLoadingImprimirOrden(false);
      }
    }
  };

  return (
    <div>
      <Dialog
        maxWidth='lg'
        open={props.open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}
          id='alert-dialog-title'
        >
          ¿Confirma la Reimpresión de la última orden?
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <PreviewBox>
              {datosOrden.orden && (
                <Grid item xs={12}>
                  <PreviewField
                    label='Nro de orden'
                    value={
                      datosOrden?.orden?.codigoTipoOrden === '02'
                        ? `${datosOrden?.orden?.numeroOrden} (${datosOrden?.orden?.tipoOrden})`
                        : datosOrden?.orden?.numeroOrden
                    }
                    color={datosOrden?.orden?.codigoTipoOrden === '02' ? 'warning' : ''}
                  />
                </Grid>
              )}
              {datosOrden.orden && (
                <Grid item xs={12}>
                  <PreviewField
                    id='order-fechageneracion'
                    label='Fecha de Emisión'
                    value={datosOrden.orden.fechaEmision}
                  />
                </Grid>
              )}
              {datosOrden.afiliado && (
                <Grid item xs={12}>
                  <PreviewField
                    id='afiliado-nya'
                    label='Afiliado'
                    value={`(${datosOrden.afiliado.numerodocumentoformateado}) ${datosOrden.afiliado.nombre}, ${datosOrden.afiliado.apellido}`}
                    multiline
                  />
                </Grid>
              )}
              {datosOrden.prestador && (
                <Grid item xs={12}>
                  <PreviewField
                    id='prestador-nya'
                    label='Prestador'
                    value={`(${datosOrden.prestador.codigoPrestador}) ${
                      datosOrden.prestador.razonSocial
                    } ${
                      datosOrden.prestador?.especialidades
                        ? `(${datosOrden.prestador?.especialidades
                            ?.map((espe) => espe.descripcion)
                            .join(', ')} ) `
                        : ''
                    } `}
                    multiline
                  />
                </Grid>
              )}
            </PreviewBox>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
            alignItems={{ xs: 'strech' }}
          >
            <Button
              variant='contained'
              startIcon={<HighlightOff />}
              color='neutral'
              onClick={handleClose}
              fullWidth
            >
              {ordenGenerada ? 'Cerrar' : 'Cancelar'}
            </Button>
            <LoadingButton
              variant='contained'
              color='primary'
              type='submit'
              startIcon={<Print />}
              loading={loadingImprimirOrden ? 'show' : 'hide'}
              onClick={handlePrint}
              disabled={!datosOrden}
              content={'Reimprimir'}
              fullWidth
            />
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrdenParaReimprimir;
