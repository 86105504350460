import { darken, lighten, styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGridPrescripciones = styled(DataGrid)(({ theme }) => ({
  // Anulada
  '& .super-app-theme--2': {
    backgroundColor: getBackgroundColor(theme.palette.error.dark, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.error.dark, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.error.dark, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.dark,
          theme.palette.mode,
        ),
      },
    },
  },

  // PROVISTA
  '& .super-app-theme-3': {
    backgroundColor: getBackgroundColor('#81db96', theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor('#81db96', theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor('#81db96', theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor('#81db96', theme.palette.mode),
      },
    },
  },

  // VENCIDA
  '& .super-app-theme--4': {
    backgroundColor: getBackgroundColor('#FFC300', theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor('#A233FF', theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor('#FFC300', theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor('#FFC300', theme.palette.mode),
      },
    },
  },
}));

export default StyledDataGridPrescripciones;
