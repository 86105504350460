import { forwardRef } from 'react';
import { FILTER_BY_MAP, usePracticas } from './hooks/usePracticas';
import { PracticasAutocomplete } from './PracticasAutocomplete';

export const PracticasPorTipoAutocomplete = forwardRef(
  ({ value, error, onChangeEvent, filterByTipoPractica, ...props }, ref) => {
    const { loading, options } = usePracticas({
      filterByAPI: FILTER_BY_MAP['byPrescripciones'],
      filterByTipoPractica: filterByTipoPractica,
    });

    const properties = {
      ...props,
      value,
      error,
      loading,
      options,
      onChangeEvent,
    };

    return <PracticasAutocomplete {...properties} />;
  },
);
