import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputAdornment,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import QrPreview from '../Common/QrPreview';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { enviarPrescripcionElectronica } from '../../services/PrescripcionElectronica/PrescripcionElectronica';
import { useTheme } from '@emotion/react';
import { isMobile as isMobileFn } from '../../@iosper/utils/DeviceDetector';
import ButtonBack from '../Breadcrumb/ButtonBack';
import {
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Info as InfoIcon,
  Help as HelpIcon,
} from '@mui/icons-material';

const estadoConfig = {
  1: {
    mensaje: (solicitudCobertura) => `
      Para generar la orden asociada a esta prescripción se requiere <strong>Auditoría Médica</strong>, por lo que fue enviada a IOSPER.
      <br/>La Solicitud de Cobertura N° <strong>${solicitudCobertura.idSolicitudCobertura}</strong> asociada se encuentra en estado <strong>${solicitudCobertura.descripcionEstadoSolicitudCobertura}</strong>.
    `,
    color: '#E3F2FD',
    textColor: '#0D47A1',
    icon: InfoIcon,
    iconColor: '#2196F3',
  },
  2: {
    mensaje: getMensajeAnuladaRechazada,
    color: '#FFEBEE',
    textColor: '#B71C1C',
    icon: CancelIcon,
    iconColor: '#F44336',
  },
  3: {
    mensaje: getMensajeAnuladaRechazada,
    color: '#FFF3E0',
    textColor: '#E65100',
    icon: WarningIcon,
    iconColor: '#FF9800',
  },
  4: {
    mensaje: (solicitudCobertura) => `
      La Solicitud de Cobertura N° <strong>${solicitudCobertura.idSolicitudCobertura}</strong> asociada a la prescripción ha sido <strong>${solicitudCobertura.descripcionEstadoSolicitudCobertura}</strong>.
      <br/>Puede proceder con la generación de la orden.
    `,
    color: '#E8F5E9',
    textColor: '#1B5E20',
    icon: CheckCircleIcon,
    iconColor: '#4CAF50',
  },
};

function getMensajeAnuladaRechazada(solicitudCobertura) {
  return `
    La Solicitud de Cobertura N° <strong>${solicitudCobertura.idSolicitudCobertura}</strong> asociada a la prescripción ha sido <strong>${solicitudCobertura.descripcionEstadoSolicitudCobertura}</strong>. 
    <br/>Por favor, revise los motivos y considere iniciar una nueva prescripción si es necesario.
  `;
}

const estadoDefault = {
  mensaje: (solicitudCobertura) => `
    La Solicitud de Cobertura N° <strong>${solicitudCobertura.idSolicitudCobertura}</strong> asociada a la prescripción se encuentra: <strong>En Revisión</strong>.  
    <br/>La Auditoría Médica se encuentra procesando su solicitud. Por favor, verifique más tarde o contacte con la delegación de IOSPER más cercana.`,

  color: '#F5F5F5',
  textColor: '#757575',
  icon: HelpIcon,
  iconColor: '#9E9E9E',
};
const EstadoSolicitudCobertura = ({
  solicitudCobertura,
  prescripcionElectronica,
  esConsulta,
  codigo,
}) => {
  const config = estadoConfig[codigo] || estadoDefault;

  const IconComponent = config.icon;

  return (
    <div
      className='container'
      style={{
        backgroundColor: config.color,
        borderRadius: '10px',
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
        marginTop: prescripcionElectronica && !esConsulta ? '-20px' : '0px',
        paddingBottom: '10px',
        marginBottom: '10px',
      }}
    >
      <IconComponent
        style={{
          color: config.iconColor,
          marginRight: '10px',
          fontSize: '24px',
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <p
            style={{ color: config.textColor, margin: 0 }}
            dangerouslySetInnerHTML={{ __html: config.mensaje(solicitudCobertura) }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const DefaultPropsTextField = {
  size: 'small',
  fullWidth: true,
  disabled: true,
  variant: 'filled',
};

const CssTextField = styled(TextField)(({ theme }) => ({
  '&.MuiTextField-root': {
    '& .MuiInputBase-formControl': {
      background: ' rgba(0, 0, 0, 0.01)',
      '& .MuiInputBase-input': {
        color: 'inherit',
        fontWeight: 'bold',
        fontSize: '13px',
        '-webkit-text-fill-color': theme.palette.text.primary,
      },
      '&:before': {
        borderBottom: '1px dotted rgba(0, 0, 0, 0.22)',
      },
    },
    '& .MuiInputLabel-root': { color: 'inherit' },
  },
}));

export const PrescripcionElectronicaGenerada = () => {
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = isMobileFn() || !matches;

  const {
    afiliado,
    profesional,
    prescripcionElectronica,
    solicitudCobertura,
    tipoPracticaCategoria,
    cie10,
    cie10Secundario,
    estado,
    practicas,
    urlReporte,
    esConsulta,
  } = history?.location?.state?.prescripcionElectronica || {};

  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [successCorreo, setSuccessCorreo] = useState('');
  const handlerEnviarCorreo = async (correo) => {
    setLoadingEnviar(true);
    setSuccessCorreo('');
    try {
      const res = await enviarPrescripcionElectronica({
        idPrescripcionElectronica: prescripcionElectronica.idPrescripcionElectronica,
        correo: correo,
      });

      setSuccessCorreo('Prescripción electrónica enviada al correo: ' + correo);
      setLoadingEnviar(false);
    } catch (e) {
      setLoadingEnviar(false);
    }
  };

  const customStyle = {};

  return (
    <Grid container spacing={2}>
      <ButtonBack />

      <Grid item xs={12}>
        <Card sx={{ paddingX: { xs: 0, sm: 1 } }}>
          <CardContent style={customStyle}>
            <Typography mb={2} variant='h5'>
              {esConsulta && 'Consulta de Prescripción Electrónica'}
              {!esConsulta && (
                <>
                  Prescripción Electrónica generada{' '}
                  <CheckCircleIcon fontSize='small' color='primary' />
                </>
              )}
            </Typography>

            <Grid container spacing={1} rowSpacing={1} gap={1}>
              {prescripcionElectronica && !esConsulta && (
                <Grid item xs={12}>
                  <QrPreview
                    showDownload={true}
                    showPrint={true}
                    urlReporte={urlReporte}
                    nombreReporte={
                      'Prescripcion_electrónica_iosper_' +
                      prescripcionElectronica.idPrescripcionElectronica
                    }
                    qrValue={JSON.stringify({
                      codigo: prescripcionElectronica.idPrescripcionElectronica,
                    })}
                    onEnviarCorreo={handlerEnviarCorreo}
                    showMail={true}
                    correo={afiliado.correo}
                    loadingEnviar={loadingEnviar}
                    successCorreo={successCorreo}
                  />
                </Grid>
              )}

              {solicitudCobertura?.codigoEstadoSolicitudCobertura && (
                <Grid item xs={12}>
                  {/* 
                  mostramos mensaje cuando: 
                  1	Iniciada  
                  2	Anulada
                  3	Rechazada
                  4	Aprobada 
                */}

                  <EstadoSolicitudCobertura
                    solicitudCobertura={solicitudCobertura}
                    prescripcionElectronica={prescripcionElectronica}
                    esConsulta={esConsulta}
                    codigo={parseInt(solicitudCobertura?.codigoEstadoSolicitudCobertura)}
                  />
                </Grid>
              )}

              {/* Prescripción */}
              <Grid item xs={12}>
                <Box
                  px={{ xs: 1, sm: 2 }}
                  py={1}
                  sx={{
                    borderRadius: { xs: 0, sm: '4px' },
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    background: 'rgb(241 241 241 / 35%)',
                  }}
                >
                  <Grid container alignItems={'flex-start'} spacing={2} rowSpacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h6' style={customStyle}>
                        Prescripción Electrónica
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      {prescripcionElectronica && (
                        <FormControl fullWidth={true} variant='standard'>
                          <CssTextField
                            id='numeroPrescripcion'
                            label='Número'
                            defaultValue={prescripcionElectronica?.idPrescripcionElectronica || '-'}
                            {...DefaultPropsTextField}
                          />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          id='plan'
                          label='Tipo de Práctica'
                          defaultValue={tipoPracticaCategoria?.descripcion || '-'}
                          {...DefaultPropsTextField}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          id='estado'
                          label='Estado'
                          defaultValue={estado?.descripcion || '-'}
                          {...DefaultPropsTextField}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          id='prescripcion'
                          label='Prescripción'
                          defaultValue={prescripcionElectronica?.fechaPrescripcion || '-'}
                          {...DefaultPropsTextField}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          id='fechaInicio'
                          label='Inicio Vigencia'
                          defaultValue={prescripcionElectronica?.fechaInicio || '-'}
                          {...DefaultPropsTextField}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          id='vencimiento'
                          label='Vencimiento'
                          defaultValue={prescripcionElectronica?.fechaVencimiento || '-'}
                          {...DefaultPropsTextField}
                        />
                      </FormControl>
                    </Grid>
                    {prescripcionElectronica?.observacionRecodificacion && (
                      <Grid item xs={12} md={12}>
                        <FormControl fullWidth={true} variant='standard'>
                          <CssTextField
                            id='observacionRecodificacion'
                            label='Observación'
                            defaultValue={prescripcionElectronica?.observacionRecodificacion || '-'}
                            {...DefaultPropsTextField}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                {/* </CardContent>
                </Card> */}
              </Grid>

              {/* Afiliado */}
              <Grid item xs={12}>
                <Box
                  px={{ xs: 1, sm: 2 }}
                  py={1}
                  sx={{
                    borderRadius: { xs: 0, sm: '4px' },
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    background: 'rgb(241 241 241 / 35%)',
                  }}
                >
                  <Grid container alignItems={'flex-start'} spacing={2} rowSpacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h6' style={customStyle}>
                        Afiliado
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          id='documentoAfiliado'
                          label='Documento'
                          defaultValue={`(${afiliado?.tipoDocumento || 'DNI'}) ${
                            afiliado.numeroDocumento
                          }`}
                          multiline
                          maxRows={3}
                          {...DefaultPropsTextField}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={8}>
                      {afiliado && (
                        <FormControl fullWidth={true} variant='standard'>
                          <CssTextField
                            id='afiliado'
                            label='Apellido y Nombre'
                            defaultValue={`${afiliado.apellido}, ${afiliado.nombre}`}
                            multiline
                            maxRows={3}
                            {...DefaultPropsTextField}
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {/* Prácticas */}
              <Grid item xs={12}>
                <Box
                  px={{ xs: 1, sm: 2 }}
                  py={1}
                  sx={{
                    borderRadius: { xs: 0, sm: '4px' },
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    background: 'rgb(241 241 241 / 35%)',
                  }}
                >
                  <Grid container alignItems={'flex-start'} spacing={2} rowSpacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h6' style={customStyle}>
                        Prácticas
                      </Typography>
                    </Grid>
                    {practicas.map((practica, idx) => {
                      const columnsByPracticas =
                        practicas.length === 1 ? 12 : practicas.length === 2 ? 6 : 4;
                      return (
                        <Grid item xs={12} md={columnsByPracticas} key={idx}>
                          <FormControl
                            sx={{ marginTop: '0px' }}
                            key={idx}
                            fullWidth={true}
                            variant='standard'
                          >
                            <CssTextField
                              id='rp1'
                              label={`RP${idx + 1}`}
                              defaultValue={`(${practica?.codigoPractica}) ${practica?.descripcionPractica} x ${practica.cantidad}`}
                              multiline
                              InputProps={{
                                endAdornment: !practica.tieneConvenio && (
                                  <InputAdornment position='end'>
                                    <Typography
                                      component='span'
                                      sx={{
                                        color: '#ff9800',
                                        fontWeight: 700,
                                        fontSize: '13px',
                                      }}
                                    >
                                      (sin convenio)
                                    </Typography>
                                  </InputAdornment>
                                ),
                              }}
                              {...DefaultPropsTextField}
                            />
                          </FormControl>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>

              {/* Diagnóstico */}
              <Grid item xs={12}>
                <Box
                  px={{ xs: 1, sm: 2 }}
                  py={1}
                  sx={{
                    borderRadius: { xs: 0, sm: '4px' },
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    background: 'rgb(241 241 241 / 35%)',
                  }}
                >
                  <Grid container alignItems={'flex-start'} spacing={2} rowSpacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h6' style={customStyle}>
                        Diagnóstico
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          id='diagnosticoPrincipal'
                          label='Diagnóstico Principal'
                          defaultValue={`(${cie10.codigo}) ${cie10.descripcion}`}
                          multiline
                          maxRows={4}
                          {...DefaultPropsTextField}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          id='diagnosticoSecundario'
                          label='Diagnóstico Secundario'
                          defaultValue={
                            cie10Secundario?.codigo
                              ? `(${cie10Secundario.codigo}) ${cie10Secundario.descripcion}`
                              : 'No especificado'
                          }
                          multiline
                          maxRows={4}
                          {...DefaultPropsTextField}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          id='indicaciones'
                          label='Indicaciones'
                          defaultValue={prescripcionElectronica?.observacion || 'No especificadas'}
                          multiline
                          maxRows={4}
                          {...DefaultPropsTextField}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {/* Prescriptor */}
              <Grid item xs={12}>
                <Box
                  px={{ xs: 1, sm: 2 }}
                  py={1}
                  sx={{
                    borderRadius: { xs: 0, sm: '4px' },
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    background: 'rgb(241 241 241 / 35%)',
                  }}
                >
                  <Grid container alignItems={'flex-start'} spacing={2} rowSpacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h6' style={customStyle}>
                        Prescriptor
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          id='profesionPrescriptor'
                          label='Profesión'
                          multiline
                          maxRows={3}
                          defaultValue={`${profesional.profesion} (Matrícula ${profesional.matricula})`}
                          {...DefaultPropsTextField}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          id='apellidoYNombrePrescriptor'
                          label='Apellido y Nombre'
                          multiline
                          maxRows={3}
                          defaultValue={profesional?.descripcion || '-'}
                          {...DefaultPropsTextField}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          id='telefonoPrescriptor'
                          label='Teléfono'
                          defaultValue={profesional?.telefono || '-'}
                          {...DefaultPropsTextField}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          id='domicilioPrescriptor'
                          label='Domicilio'
                          defaultValue={profesional?.domicilio || '-'}
                          {...DefaultPropsTextField}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Typography
              variant='h6'
              color='textSecondary'
              component='p'
              mt={3}
              textAlign={'center'}
            >
              Prescripción firmada electrónicamente
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
