import { FormControl, styled, TextField } from '@mui/material';

const DefaultPropsTextField = {
  size: 'small',
  fullWidth: true,
  disabled: true,
  variant: 'filled',
};

const CssTextField = styled(TextField)(({ theme }) => ({
  '&.MuiTextField-root': {
    '& .MuiInputBase-formControl': {
      background: ' rgba(0, 0, 0, 0.01)',
      '& .MuiInputBase-input': {
        color: 'inherit',
        fontWeight: 'bold',
        fontSize: '13px',
        // '-webkit-text-fill-color': theme.palette.text.primary,
        WebkitTextFillColor: theme.palette.text.primary,
      },
      '&:before': {
        borderBottom: '1px dotted rgba(0, 0, 0, 0.22)',
      },
    },
    '& .MuiInputLabel-root': { color: 'inherit' },
  },
}));

export const PreviewField = ({ id, label, value, ...props }) => {
  return (
    <FormControl fullWidth={true} variant='standard'>
      <CssTextField
        {...DefaultPropsTextField}
        {...props}
        id={id}
        label={label}
        defaultValue={value}
      />
    </FormControl>
  );
};
