import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import QrPreview from '../Common/QrPreview';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { enviarRecetaElectronica } from '../../services/RecetaElectronica/RecetaElectronica';
import { useTheme } from '@emotion/react';
import { isMobile as isMobileFn } from '../../@iosper/utils/DeviceDetector';
import ButtonBack from '../Breadcrumb/ButtonBack';
import { Info as InfoIcon, Warning as WarningIcon } from '@mui/icons-material';
import { PreviewBox } from '../Common/PreviewBox';
import { PreviewField } from '../Common/PreviewField';

const customStyle = {};

const DefaultPropsTextField = {
  size: 'small',
  fullWidth: true,
  disabled: true,
  variant: 'filled',
};

const CssTextField = styled(TextField)(({ theme }) => ({
  '&.MuiTextField-root': {
    '& .MuiInputBase-formControl': {
      background: ' rgba(0, 0, 0, 0.01)',
      '& .MuiInputBase-input': {
        color: 'inherit',
        fontWeight: 'bold',
        fontSize: '13px',
        // '-webkit-text-fill-color': theme.palette.text.primary,
        WebkitTextFillColor: theme.palette.text.primary,
      },
      '&:before': {
        borderBottom: '1px dotted rgba(0, 0, 0, 0.22)',
      },
    },
    '& .MuiInputLabel-root': { color: 'inherit' },
  },
}));

export const RecetaMedicaGenerada = () => {
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = isMobileFn() || !matches;

  const {
    afiliado,
    recetaElectronica,
    grupoPlan,
    estado,
    rpList,
    tipoTratamiento,
    idsRecetas, //todas
    idsRecetasAsociadas, //solo ids de asociadas (no tiene id de la consultada)
    profesional,
    urlReporte,
    esConsulta,
  } = history?.location?.state?.receta || {};

  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [successCorreo, setSuccessCorreo] = useState('');
  //const [urlReporte, setUrlReporte] = useState("");
  const handlerEnviarCorreo = async (correo) => {
    setLoadingEnviar(true);
    setSuccessCorreo('');
    try {
      const res = await enviarRecetaElectronica({
        idsRecetas: idsRecetas,
        correo: correo,
      });

      setSuccessCorreo('Receta electrónica enviada al correo: ' + correo);
      setLoadingEnviar(false);
    } catch (e) {
      setLoadingEnviar(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <ButtonBack />

      <Grid item xs={12}>
        <Card sx={{ paddingX: { xs: 0, sm: 1 } }}>
          <CardContent style={customStyle}>
            <Box
              display={'flex'}
              direction='row'
              justifyContent={esConsulta ? 'center' : 'flex-start'}
              alignItems='center'
              mb={2}
              width={'100%'}
            >
              <Typography variant='h5' component={'span'}>
                {esConsulta && <>Consulta de Receta Electrónica</>}
                {!esConsulta && <>Receta Electrónica generada</>}
              </Typography>
              {!esConsulta && <CheckCircleIcon fontSize='small' color='primary' sx={{ ml: 2 }} />}
            </Box>

            <Grid container spacing={1} rowSpacing={1} gap={1}>
              {recetaElectronica && !esConsulta && (
                <Grid item xs={12}>
                  <QrPreview
                    showDownload={true}
                    showPrint={true}
                    urlReporte={urlReporte}
                    nombreReporte={'Receta_electrónica_iosper_' + recetaElectronica.numero}
                    qrValue={JSON.stringify({ codigo: recetaElectronica.numero })}
                    onEnviarCorreo={handlerEnviarCorreo}
                    showMail={true}
                    correo={afiliado.correo}
                    loadingEnviar={loadingEnviar}
                    successCorreo={successCorreo}
                  />
                </Grid>
             )}
            

              {grupoPlan?.observacion != null && (
                <div
                  className='container'
                  style={{
                    backgroundColor: '#E3F2FD',
                    borderRadius: '10px',
                    padding: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: recetaElectronica && !esConsulta ? '-20px' : '0px',
                    paddingBottom: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <InfoIcon
                    style={{
                      color: '#2196F3',
                      marginRight: '10px',
                      fontSize: '24px',
                    }}
                  />
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <p
                        style={{
                          color: '#0D47A1',
                          margin: 0,
                          fontSize: '14px',
                          textAlign: isMobile ? 'justify' : 'left',
                        }}
                      >
                        Esta receta médica requiere autorización previa de{' '}
                        <strong>Auditoría Médica</strong> de IOSPER antes de poder ser dispensada en
                        la farmacia.
                      </p>
                    </Grid>
                  </Grid>
                </div>
              )}

              <Grid item xs={12}>
                <PreviewBox title='Receta Electrónica'>
                  <Grid item xs={6} md={4}>
                    <PreviewField
                      id='numeroReceta'
                      label='Número'
                      value={`${recetaElectronica.numero || ''}`}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <PreviewField
                      id='plan'
                      label='Plan'
                      value={`${recetaElectronica.planDescripcion || recetaElectronica.grupoPlanObservacion}`}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <PreviewField id='estado' label='Estado' value={`${estado?.descripcion}`} />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <PreviewField
                      id='prescripcion'
                      label='Prescripción'
                      value={`${recetaElectronica.fechaPrescripcion}`}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <PreviewField
                      id='fechaInicio'
                      label='Inicio Vigencia'
                      value={`${recetaElectronica.fechaInicioVigenciaFormateada}`}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <PreviewField
                      id='vencimiento'
                      label='Vencimiento'
                      value={`${recetaElectronica.vencimientoFormateado}`}
                    />
                  </Grid>
                  {estado?.codigo === '2' && (
                    <>
                      <Grid item xs={6} md={6}>
                        <PreviewField
                          id='fechaEntregada'
                          label='Fecha entrega'
                          value={`${recetaElectronica.fechaEstado}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <PreviewField
                          id='farmacia'
                          label='Farmacia'
                          value={`${recetaElectronica.farmacia}`}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={6} md={6}>
                    <PreviewField
                      id='tipoTratamiento'
                      label='Tipo de Tratamiento'
                      value={`${tipoTratamiento.descripcion} ${
                        recetaElectronica.periodo != null ? recetaElectronica.periodo : ''
                      }`}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <PreviewField
                      id='tipoReceta'
                      label='Tipo de Receta'
                      value={`${recetaElectronica.tipoRecetaElectronicaDescripcion}`}
                    />
                  </Grid>
                </PreviewBox>
              </Grid>

              <Grid item xs={12}>
                <PreviewBox title='Afiliado'>
                  <Grid item xs={12} md={6}>
                    <PreviewField
                      id='documentoAfiliado'
                      label='Documento'
                      value={`(${afiliado.tipoDocumentoDescripcion}) ${afiliado.numeroDocumentoFormateado}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PreviewField
                      id='afiliado'
                      label='Apellido y Nombre'
                      value={`${afiliado.apellido}, ${afiliado.nombre}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PreviewField
                      id='fechaNacimiento'
                      label='Fecha Nacimiento'
                      value={`${afiliado.fechaNacimiento}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PreviewField id='sexoAfiliado' label='Sexo' value={`${afiliado.sexo}`} />
                  </Grid>
                  {recetaElectronica?.domicilioAfiliado && (
                    <>
                      <Grid item xs={12} md={6}>
                        <PreviewField
                          {...DefaultPropsTextField}
                          id='telefono'
                          label='Teléfono'
                          value={`${recetaElectronica?.telefonoAfiliado}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <PreviewField
                          {...DefaultPropsTextField}
                          id='domicilio'
                          label='Domicilio'
                          value={`${recetaElectronica?.domicilioAfiliado}`}
                        />
                      </Grid>
                    </>
                  )}
                </PreviewBox>
              </Grid>

              <Grid item xs={12}>
                <PreviewBox title='Prescripciones'>
                  {rpList.map((rp, idx) => {
                    const colPracticas = rpList.length === 1 ? 12 : rpList.length === 2 ? 6 : 4;
                    return (
                      <Grid item xs={12} md={colPracticas} key={idx}>
                        <PreviewField
                          id='rp1'
                          label={`RP${idx + 1}`}
                          value={`${rp?.medicamento?.drogas || '-'} ${
                            rp.medicamento.presentacion
                          }, cantidad: ${rp.medicamento.cantidad}(${rp.cie10.descripcion} (${
                            rp.cie10?.codigo
                          }))`}
                          multiline
                          minRows={1}
                        />
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <PreviewField
                      id='indicaciones'
                      label='Indicaciones'
                      value={`${recetaElectronica.observacion}`}
                      multiline
                      maxRows={4}
                    />
                  </Grid>
                </PreviewBox>
              </Grid>

              <Grid item xs={12}>
                <PreviewBox title='Prescriptor'>
                  <Grid item xs={12} md={6}>
                    <PreviewField
                      id='profesionPrescriptor'
                      label='Profesión'
                      value={`${profesional.profesion} (Matrícula ${profesional.matricula})`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PreviewField
                      id='apellidoYNombrePrescriptor'
                      label='Apellido y Nombre'
                      value={`${profesional.descripcion}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PreviewField
                      id='telefonoPrescriptor'
                      label='Teléfono'
                      value={`${profesional?.telefono || '-'}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PreviewField
                      id='domicilioPrescriptor'
                      label='Domicilio'
                      value={`${profesional?.domicilio}`}
                    />
                  </Grid>
                </PreviewBox>
              </Grid>

              {idsRecetasAsociadas.length > 0 && (
                <Grid item xs={12}>
                  <PreviewBox title='Otras recetas del tratamiento'>
                    <Box display={'flex'} gap={1} mx={2} my={1}>
                      {idsRecetasAsociadas.map((recetaAsociada, idx) => {
                        return (
                          <Button key={idx} variant='outlined'>
                            {recetaAsociada.numero}
                          </Button>
                        );
                      })}
                    </Box>
                  </PreviewBox>
                </Grid>
              )}
            </Grid>

            {recetaElectronica?.observacionValidacion != null && (
              <div
                className='container'
                style={{
                  backgroundColor: '#E3F2FD',
                  borderRadius: '10px',
                  padding: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: recetaElectronica && !esConsulta ? '10px' : '20px',
                  paddingBottom: '10px',
                  marginBottom: '10px',
                }}
              >
                <InfoIcon
                  style={{
                    color: '#2196F3',
                    marginRight: '10px',
                    fontSize: '24px',
                  }}
                />
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <p
                      style={{
                        color: '#0D47A1',
                        margin: 0,
                        fontSize: '14px',
                        textAlign: isMobile ? 'justify' : 'left',
                      }}
                    >
                      {recetaElectronica?.observacionValidacion}
                    </p>
                  </Grid>
                </Grid>
              </div>
            )}

            <Typography
              variant='h6'
              color='textSecondary'
              component='p'
              mt={3}
              textAlign={'center'}
            >
              Receta firmada electrónicamente
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
