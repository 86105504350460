import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Grid, Stack } from '@mui/material';
import { getGenerarOrden, getImprimirOrden } from '../../services/Seo/Orden';
import LoadingButton from '../../@iosper/components/LoadingButton';

import Assignment from '@mui/icons-material/Assignment';
import HighlightOff from '@mui/icons-material/HighlightOff';
import DetalleCoberturaOrdenData from './components/DetalleCoberturaOrdenData';
import { PreviewBox } from '../Common/PreviewBox';
import { PreviewField } from '../Common/PreviewField';

const OrdenValidadaResumidaForm = ({ onClose, ...props }) => {
  const [datosOrden, setDatosOrden] = useState({});
  const [ordenGenerada, setOrdenGenerada] = useState(false);
  const [loadingGenerarOrden, setLoadingGenerarOrden] = useState(false);
  const { handlerOrdenConfirmada } = props;

  useEffect(() => {
    setDatosOrden(props?.datosOrden);

    return function cleanup() {
      setDatosOrden({});
    };
  }, [props.datosOrden]);

  if (!datosOrden) {
    return null;
  }

  const handleClose = (e) => {
    setDatosOrden({});
    setLoadingGenerarOrden(false);
    setOrdenGenerada(false);
    if (onClose) {
      onClose(e);
    }
  };

  const handleGenerate = async (event) => {
    event.preventDefault();
    setLoadingGenerarOrden(true);

    try {
      const res = await getGenerarOrden(datosOrden.codigoTransaccion);
      setDatosOrden(res);
      setOrdenGenerada(true);
      const resGenerada = await getImprimirOrden({
        numeroOrden: res.orden.numeroOrdenSinFormato,
        codigoTransaccion: res.orden.codigoTransaccion,
      });
      setLoadingGenerarOrden(false);
      handlerOrdenConfirmada(res, resGenerada);
      setDatosOrden({});
      handleClose();
    } catch (e) {
      setLoadingGenerarOrden(false);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth='lg'
        open={props.open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center' }} id='alert-dialog-title'>
          ¿Confirma esta orden?
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <PreviewBox>
              {datosOrden.practicas &&
                Object.values(datosOrden.practicas).map((practica, idx) => {
                  const pList = Object.values(datosOrden.practicas) || [];
                  const colPracticas = pList.length === 1 ? 12 : pList.length === 2 ? 6 : 4;
                  return (
                    <Grid item xs={12} md={colPracticas} key={idx}>
                      <PreviewField
                        label={`Práctica ${idx + 1}`}
                        value={`(${practica.codigoPractica}) ${practica.descripcionPractica}`}
                        multiline
                        minRows={2}
                      />
                    </Grid>
                  );
                })}
              {props.datosOrden.afiliado && (
                <Grid item xs={12}>
                  <PreviewField
                    id='afiliado'
                    label='Afiliado'
                    value={`(${props.datosOrden.afiliado.numerodocumentoformateado}) ${props.datosOrden.afiliado.nombre}, ${props.datosOrden.afiliado.apellido}`}
                  />
                </Grid>
              )}
              {props.datosOrden.prestador && (
                <Grid item xs={12}>
                  <PreviewField
                    id='prestador'
                    label='Prestador'
                    value={`(${props.datosOrden.prestador.codigoPrestador}) ${
                      props.datosOrden.prestador.razonSocial
                    } ${
                      props.datosOrden.prestador?.especialidades
                        ? `(${props.datosOrden.prestador?.especialidades
                            ?.map((espe) => espe.descripcion)
                            .join(', ')} ) `
                        : ''
                    } `}
                  />
                </Grid>
              )}
              {props.datosOrden.prescriptor && (
                <Grid item xs={12}>
                  <PreviewField
                    id='prescriptor'
                    label='Prescriptor'
                    value={`(${props.datosOrden.prescriptor.codigoPrestador}) ${
                      props.datosOrden.prescriptor.razonSocial
                    } ${
                      props.datosOrden.prescriptor?.especialidades
                        ? `(${props.datosOrden.prescriptor?.especialidades
                            ?.map((espe) => espe.descripcion)
                            .join(', ')} ) `
                        : ''
                    } `}
                  />
                </Grid>
              )}
              {props.datosOrden.totales && (
                <Grid item xs={12}>
                  <PreviewField
                    id='coseguro'
                    label='Coseguro a pagar al Prestador'
                    value={`$ ${props.datosOrden.totales.totalPagar}`}
                  />
                </Grid>
              )}
              {props.datosOrden.practicas && (
                <Grid item xs={12}>
                  <DetalleCoberturaOrdenData
                    detallePracticas={props.datosOrden.practicas}
                    nuevaVistaPreview={true}
                  />
                </Grid>
              )}
            </PreviewBox>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
            alignItems={{ xs: 'strech' }}
          >
            <Button
              variant='contained'
              color='neutral'
              startIcon={<HighlightOff />}
              onClick={handleClose}
            >
              {ordenGenerada ? 'Cerrar' : 'Cancelar'}
            </Button>
            <LoadingButton
              variant='contained'
              color='primary'
              type='submit'
              startIcon={<Assignment />}
              loading={loadingGenerarOrden ? 'show' : 'hide'}
              autoFocus={true}
              onClick={handleGenerate}
              disabled={ordenGenerada}
              content={ordenGenerada ? 'Confirmada' : 'Confirmar'}
            />
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrdenValidadaResumidaForm;
