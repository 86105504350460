import { useEffect, useMemo, useState } from 'react';
import {
  getPracticasOrdenConsulta,
  getPracticasOrdenPractica,
  getPracticasPrescripciones,
} from '../../../services/Busquedas/Practicas';

export const FILTER_BY_MAP = {
  byOrdenesConsultas: 'OrdenesConsultas',
  byOrdenesPracticas: 'OrdenesPracticas',
  byPrescripciones: 'Prescripciones',
};

const valueInit = {
  filterByAPI: null,
  filterByTipoPractica: null,
  filterByCodigoSubtipoPractica: null,
};

export const usePracticas = (value = valueInit) => {
  const { filterByAPI, filterByTipoPractica, filterByCodigoSubtipoPractica } = value;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const getPracticaList = async () => {
    setLoading(true);
    const practicas = await getPracticasByFilter(filterByAPI);
    const filteringPracticas = applyFilters(practicas);

    setOptions(filteringPracticas || []);
    setLoading(false);
    return practicas;
  };

  const getPracticasByFilter = async (filter) => {
    switch (filter) {
      case FILTER_BY_MAP.byOrdenesConsultas:
        return getPracticasOrdenConsulta();
        break;
      case FILTER_BY_MAP.byOrdenesPracticas:
        return getPracticasOrdenPractica();
        break;
      case FILTER_BY_MAP.byPrescripciones:
        return getPracticasPrescripciones();
        break;

      default:
        break;
    }
  };

  const applyFilters = (value) => {
    let list = value;
    if (filterByTipoPractica && list) {
      list = list?.filter((p) => p.idGrupoInosPracticaCategoria === filterByTipoPractica);
    }
    if (filterByCodigoSubtipoPractica && list) {
      list = list.filter((item) => item.codigoSubtipoPractica === filterByCodigoSubtipoPractica);
    }

    return list;
  };

  useMemo(() => {
    setOptions([]);
    getPracticaList();
  }, [filterByTipoPractica, filterByCodigoSubtipoPractica]);

  return {
    options,
    loading,
  };
};
