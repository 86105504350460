import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
  ToggleButton,
  Typography,
  FormControl,
  Alert,
  Input,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { getMedicamentos } from '../../services/Busquedas/Medicamentos';
import { useWidth } from '../../@iosper/utils/UseWidth';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { DataGrid } from '@mui/x-data-grid';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import LoadingButton from '../../@iosper/components/LoadingButton';
import { useForm } from 'react-hook-form';
import ModalBase from '../Controls/ModalBase';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@mui/styles';

const DataCollapseDrawer = (props) => {
  const { Medicamento } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        selected={open}
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <Typography
            variant='body1'
            align='left'
            gutterBottom={false}
            sx={{ color: '#000', textTransform: 'none' }}
          >
            {`${Medicamento.drogas || ''} (${Medicamento.presentacion || 'Sin presentación'})`}
          </Typography>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <List>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary={`Laboratorio: ${Medicamento?.laboratorio || ''}`} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary={`Nombre: ${Medicamento?.nombre || ''}`} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary={`Control: ${Medicamento?.control || ''}`} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary={`Cobertura Plan A:`} />
              {Medicamento?.cobertura != '0%' ? (
                <Typography sx={{ fontWeight: 'bold' }} style={{ color: 'primary' }}>
                  {Medicamento?.cobertura || ''}
                </Typography>
              ) : (
                <Typography sx={{ fontWeight: 'bold' }} style={{ color: '#B12823' }}>
                  {Medicamento?.cobertura || ''}
                </Typography>
              )}
            </ListItem>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const getMedicamentoNombre = (medicamento) => {
  if (!medicamento) return;
  let drogras = medicamento?.drogas ? `(${medicamento.drogas})` : '';
  let presentacion = `(${medicamento.presentacion})`;
  return `${drogras} ${medicamento?.nombre} ${presentacion}`;
};

const useStyles = makeStyles({
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    cursor: 'pointer',
  },
});

const MedicamentoSelector = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const screenWidth = useWidth();
  const { onSelectedMedicamento, errorSelector } = props;
  const [medicamentos, setMedicamentos] = useState([]);
  const [rowSelectedDataGrid, setRowSelectedDataGrid] = useState(null);
  const [disabledSearchMedicamento, setDisabledSearchMedicamento] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertResultFind, setAlertResultFind] = useState('');
  const [medicamento, setMedicamento] = useState(props.medicamento || null);
  const [idGrupoPlan, setIdGrupoPlan] = useState(props.idGrupoPlan || null);
  const [visibleFind, setVisibleFind] = useState(true);
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [textFieldMedicamento, setTextFieldMedicamento] = useState('Buscá el Medicamento');
  const [error, setError] = useState('');

  React.useEffect(() => {
    setMedicamento(props.medicamento || null);
  }, [props.medicamento]);

  React.useEffect(() => {
    setIdGrupoPlan(props.idGrupoPlan || null);
  }, [props.idGrupoPlan]);

  React.useEffect(() => {
    onSelectedMedicamento(medicamento);
  }, [medicamento]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const handleClose = () => {
    setOpen(false);
  };

  const openCloseModal = () => {
    if (!open) {
      clean();
    }
    setOpen(!open);
  };

  const clean = () => {
    setMedicamentos([]);
    //onSelectedMedicamento(null);
    setMedicamento(null);
    setVisibleFind(true);
    setRowSelectedDataGrid(null);
    reset();
    setVisibleInfo(false);
  };

  const find = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    setVisibleInfo(false);
    setAlertResultFind('');
    setError('');

    if (data.nombre === '') {
      setError('Debes ingresar un medicamento para realizar tu búsqueda.');
      setLoading(false);
    } else {
      const result = await getMedicamentos({
        nombre: data.nombre,
        droga: data.nombre,
        idGrupoPlan: idGrupoPlan,
      });
      if (result && result.length > 0) {
        if (result.length >= 50) {
          setVisibleInfo(true);
        }
        setMedicamentos(result);
        setVisibleFind(false);
      } else {
        setAlertResultFind('No se han encontrado Medicamentos');
        clean();
      }
      setLoading(false);
    }
  };

  const onChangeCantidadMedicamento = (e) => {
    if (medicamento) {
      setMedicamento({ ...medicamento, cantidad: e.target.value });
    }
  };

  const selectHandler = (e) => {
    e.preventDefault();
    // onSelectedMedicamento(rowSelectedDataGrid);
    setMedicamento({ ...rowSelectedDataGrid, cantidad: 1 });
    setDisabledSearchMedicamento(true);
    openCloseModal();
    reset();
    setTextFieldMedicamento('Medicamento');
    setVisibleInfo(false);
  };

  const handleIconClose = (e) => {
    e.preventDefault();
    setDisabledSearchMedicamento(false);
    setTextFieldMedicamento('Buscá el Medicamento');
    setMedicamento(null);
    // onSelectedMedicamento(null);
  };

  const handleOnClickIconSearch = (e) => {
    e.preventDefault();
    openCloseModal();
  };

  const handleClickMedicamento = (e) => {
    if (!disabledSearchMedicamento) {
      openCloseModal();
    }
  };

  const handleOnRowDoubleClick = (data, e) => {
    e.preventDefault();
    selectHandler(e);
    setRowSelectedDataGrid(data);
  };

  /// -------------------- DATA GRID Medicamentos --------------------------
  const mapMedicamentoToRow = (p, key) => ({
    id: `${key}-${p.medicamentoVO.registro}`,
    idMedicamento: p.medicamentoVO.registro,
    nombre: p.medicamentoVO.nombre,
    drogas: p.drogas.map((droga) => droga.descripcion),
    presentacion: p.medicamentoVO.presentacion,
    laboratorio: p.medicamentoVO.laboratorio,
    cobertura: (p.medicamentoVO.cobertura || '0') + '%',
    isControlado: p.medicamentoVO.isControlado,
    control: p.medicamentoVO.control,
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Medicamentos',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer Medicamento={a.row} />,
    },
    { field: 'drogas', headerName: 'Drogas', flex: 0.1 },
    { field: 'presentacion', headerName: 'Presentación', flex: 0.1 },
    { field: 'nombre', headerName: 'Nombre', flex: 0.1 },
    { field: 'laboratorio', headerName: 'Laboratorio', flex: 0.1 },
    { field: 'cobertura', headerName: 'Cobertura Plan A', flex: 0.1 },
    { field: 'control', headerName: 'Control', flex: 0.1 },
  ];

  const dataGridMedicamentos = (
    <Grid container direction='column' justifyContent='space-between' spacing={1}>
      <Grid item>
        <div style={{ height: '60vh', width: '100%' }}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-cell': {
                py: '8px',
              },
              '& .sinCobertura': {
                color: '#B12823',
                fontWeight: 'bold',
              },
            }}
            loading={!medicamentos.length}
            rows={medicamentos.map((a, key) => mapMedicamentoToRow(a, key))}
            getCellClassName={(params) => {
              if (params.field == 'cobertura') {
                return params.row.cobertura == '0%' ? 'sinCobertura' : '';
              }
            }}
            columns={columns}
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}
            columnVisibilityModel={{
              desplegable: screenWidth === 'xs',
              descripcionTipo: screenWidth !== 'xs',
              drogas: screenWidth !== 'xs',
              presentacion: screenWidth !== 'xs',
              nombre: screenWidth !== 'xs',
              laboratorio: screenWidth !== 'xs',
              cobertura: screenWidth !== 'xs',
              control: screenWidth !== 'xs',
            }}
            pagination
            autoPageSize
            onRowClick={(selected) => {
              setRowSelectedDataGrid(selected.row);
            }}
            onRowDoubleClick={handleOnRowDoubleClick}
          />
        </div>
      </Grid>
      {visibleInfo && (
        <Grid item>
          <Alert severity='info'>
            Sólo se muestran los primeros 50 resultados, agregue más criterios de búsqueda.
          </Alert>
        </Grid>
      )}
      <Grid item>
        <Grid container justifyContent='flex-end' spacing={1}>
          <Grid item>
            {' '}
            <Button
              variant='contained'
              color='neutral'
              startIcon={<SearchIcon />}
              onClick={() => clean()}
            >
              Buscar otro
            </Button>
          </Grid>
          <Grid item>
            {' '}
            <Button
              variant='contained'
              color='primary'
              disabled={!rowSelectedDataGrid}
              startIcon={<CheckCircleIcon />}
              onClick={(e) => selectHandler(e)}
            >
              Seleccionar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const formFind = (
    <form>
      <FormControl fullWidth={true} variant='outlined'>
        <TextField
          variant='standard'
          type='text'
          fullWidth
          margin='normal'
          name='nombre'
          label='Ingresá principio activo o nombre comercial'
          {...register('nombre', {
            maxLength: {
              value: 200,
              message: 'El campo es demasiado largo',
            },
            minLength: {
              value: 3,
              message: 'El campo debe tener como mínimo 3 caracteres.',
            },
          })}
        />
        {errors['nombre'] && (
          <p style={{ color: 'red', marginTop: '1px' }}>{errors['nombre'].message}</p>
        )}
      </FormControl>
      <Grid container justifyContent='flex-end'>
        <Grid item>
          <LoadingButton
            fullWidth={true}
            type='submit'
            variant='contained'
            color='primary'
            loading={loading ? 'show' : 'hide'}
            content={'Buscar'}
            startIcon={<SearchIcon />}
            onClick={handleSubmit(find)}
          />
        </Grid>
      </Grid>
    </form>
  );

  const iconAdornment = !disabledSearchMedicamento ? (
    <IconButton
      variant='outlined'
      aria-label='Buscar Medicamento'
      onClick={handleOnClickIconSearch}
      size='large'
      edge='end'
    >
      <SearchIcon />
    </IconButton>
  ) : (
    <IconButton
      variant='outlined'
      aria-label='Buscar Medicamento'
      onClick={handleIconClose}
      size='large'
      edge='end'
    >
      <CloseSharpIcon />
    </IconButton>
  );

  const handleBorrarMensaje = () => {
    setAlertResultFind('');
    setError('');
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3} alignItems='flex-end'>
        <Grid item sm={9} xs={12}>
          <div style={{ position: 'relative' }}>
            <FormControl fullWidth variant='standard'>
              <InputLabel htmlFor='outlined-adornment-Medicamento'>
                {textFieldMedicamento}
              </InputLabel>
              <Input
                id='outlined-adornment-Medicamento'
                name='Medicamento'
                multiline
                maxRows={4}
                label='Buscá el Medicamento'
                aria-describedby='outlined-Medicamento-helper-text'
                value={medicamento ? getMedicamentoNombre(medicamento) : ''}
                onClick={handleClickMedicamento}
                onBlur={handleClickMedicamento}
                disabled
                endAdornment={<InputAdornment position='end'>{iconAdornment}</InputAdornment>}
              />
            </FormControl>
            <div className={classes.overlay} onClick={handleClickMedicamento} />
          </div>
          {errorSelector && screenWidth === 'xs' && (
            <p style={{ color: 'red', marginTop: '1px' }}>{errorSelector}</p>
          )}
        </Grid>

        <Grid item sm xs={12}>
          <FormControl fullWidth variant='standard'>
            <InputLabel htmlFor='cantidad-medicamento'>Cantidad</InputLabel>
            <Input
              id='cantidad-medicamento'
              aria-describedby='Cantidad de unidades del medicamento seleccionado.'
              type='number'
              value={medicamento?.cantidad || '1'}
              name='cantidad'
              onChange={onChangeCantidadMedicamento}
              inputProps={{
                step: 1,
                minLength: 0,
                maxLength: 2,
                min: 1,
                max: 99,
                type: 'number',
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <ModalBase
        fullWidth={true}
        open={open}
        content={visibleFind ? formFind : dataGridMedicamentos}
        title='Buscar Medicamento'
        onClose={handleClose}
      />
      <CustomizedSnackbars
        open={alertResultFind}
        severity='info'
        message={alertResultFind}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Box>
  );
});

export default MedicamentoSelector;
