import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Box } from '@mui/material';
import { useUser } from '../components/Auth/User';

/* Screens */
import Home from '../components/Screens/Home';

/* Components */
import SignIn from '../components/Auth/SignIn';
import SignOut from '../components/Auth/SignOut';
import CambiarPassword from '../components/Auth/CambiarPassword';
import TerminosCondiciones from '../components/Screens/TerminosCondiciones';
import Perfil from '../components/Screens/Perfil';
import Ayuda from '../components/Screens/Ayuda';
import BuscarOrdenes from '../components/Orden/BuscarOrdenes';
import EmitirOrdenDigital from '../components/Orden/EmitirOrdenDigital';
import RegistrarAtencion from '../components/Orden/RegistrarAtencion';
import ConsultarCupo from '../components/Orden/ConsultarCupo';
import Mantenimiento from '../layouts/Messages/Page/Mantenimiento';
import RecetaMedica from '../components/Receta/RecetaMedica';
import EmitirOrdenConsulta from '../components/Orden/EmitirOrdenConsulta';
import EmitirOrdenVaria from '../components/Orden/EmitirOrdenVaria';
import DocumentoPreview from '../components/Screens/DocumentoPreview';
import BuscarRecetas from '../components/Receta/BuscarRecetas';
import { RecetaMedicaGenerada } from '../components/Receta/RecetaMedicaGenerada';
import ValidarContacto from '../components/Contacto/ValidarContacto';
import RecuperarPin from '../components/Contacto/RecuperarPin/RecuperarPin';
import OlvidePin from '../components/Contacto/RecuperarPin/OlvidePin';
import ConfirmarContactoView from '../components/Contacto/ConfirmarContactoView';
import AnularAtencion from '../components/Orden/AnularAtencion';
import BuscarAnularOrdenElectronica from '../components/Orden/BuscarAnularOrdenElectronica';
import ReimprimirOrden from '../components/Orden/ReimprimirOrden';
import AnularOrdenImpresa from '../components/Orden/AnularOrdenImpresa';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import BuscarPrescripcionesElectronicas from '../components/PrescripcionElectronica/components/BuscarPrescripcionElectronica';
import { PrescripcionElectronicaGenerada } from '../components/PrescripcionElectronica/PrescripcionElectronicaGenerada';
import { GenerarPrescripcionElectronica } from '../components/PrescripcionElectronica/GenerarPrescripcionElectronica';
import { RecetaMedicaEspeciales } from '../components/Receta/RecetaMedicaEspeciales';

const PrivateRoute = ({ children, ...props }) => {
  const { user } = useUser();

  return (
    <Route
      {...props}
      render={({ location }) =>
        user ? children : <Redirect to={{ pathname: '/signin', state: { from: location } }} />
      }
    />
  );
};

const Pages = (props) => (
  <Box>
    <Switch>
      {/* Public routes */}
      <Route path='/signin'>
        <SignIn />
      </Route>
      <Route path='/mantenimiento'>
        <Mantenimiento />
      </Route>
      <Route path='/recuperar-pin'>
        <RecuperarPin />
      </Route>
      <Route path='/olvide-pin'>
        <OlvidePin />
      </Route>

      {/* Private routes */}
      <PrivateRoute exact path='/'>
        <Home />
      </PrivateRoute>
      <PrivateRoute exact path='/perfil'>
        <Perfil />
      </PrivateRoute>
      <PrivateRoute exact path='/terminos-condiciones'>
        <TerminosCondiciones />
      </PrivateRoute>
      <PrivateRoute path='/signout'>
        <SignOut />
      </PrivateRoute>
      <PrivateRoute path='/cambiar-password'>
        <CambiarPassword />
      </PrivateRoute>
      <PrivateRoute path='/orden-digital'>
        <EmitirOrdenDigital />
      </PrivateRoute>
      <PrivateRoute path='/registrar-atencion'>
        <RegistrarAtencion />
      </PrivateRoute>
      <PrivateRoute path='/anular-atencion'>
        <AnularAtencion />
      </PrivateRoute>
      <PrivateRoute path='/consultar-ordenes-electronicas'>
        <BuscarOrdenes electronicas={true} />
      </PrivateRoute>
      <PrivateRoute path='/consultar-ordenes-impresas'>
        <BuscarOrdenes electronicas={false} />
      </PrivateRoute>
      <PrivateRoute path='/anular-orden-electronica'>
        <BuscarAnularOrdenElectronica />
      </PrivateRoute>
      <PrivateRoute path='/generar-receta/:id'>
        <RecetaMedica />
      </PrivateRoute>
      <PrivateRoute path='/generar-receta'>
        <RecetaMedica />
      </PrivateRoute>
      <PrivateRoute path='/consultar-recetas/:id'>
        <BuscarRecetas />
      </PrivateRoute>
      <PrivateRoute path='/consultar-recetas'>
        <BuscarRecetas />
      </PrivateRoute>
      <PrivateRoute path='/receta-generada'>
        <RecetaMedicaGenerada />
      </PrivateRoute>
      <Route path='/generar-prescripcion-electronica/:id?/:esRecodificacion?'>
        <GenerarPrescripcionElectronica />
      </Route>
      <PrivateRoute path='/generar-prescripcion-electronica/:id'>
        <GenerarPrescripcionElectronica />
      </PrivateRoute>
      <PrivateRoute path='/generar-prescripcion-electronica'>
        <GenerarPrescripcionElectronica />
      </PrivateRoute>
      <PrivateRoute path='/consultar-prescripciones-electronicas/:id'>
        <BuscarPrescripcionesElectronicas />
      </PrivateRoute>
      <PrivateRoute path='/consultar-prescripciones-electronicas'>
        <BuscarPrescripcionesElectronicas />
      </PrivateRoute>
      <PrivateRoute path='/prescripcion-electronica-generada'>
        <PrescripcionElectronicaGenerada />
      </PrivateRoute>
      <PrivateRoute path='/generar-receta-especiales/:id'>
        <RecetaMedicaEspeciales />
      </PrivateRoute>
      <PrivateRoute path='/generar-receta-especiales'>
        <RecetaMedicaEspeciales />
      </PrivateRoute>
      <PrivateRoute path='/orden-consulta'>
        <EmitirOrdenConsulta />
      </PrivateRoute>
      <PrivateRoute path='/orden-varia'>
        <EmitirOrdenVaria />
      </PrivateRoute>
      <PrivateRoute path='/reimpresion-orden'>
        <ReimprimirOrden />
      </PrivateRoute>
      <PrivateRoute path='/anular-orden-impresa'>
        <AnularOrdenImpresa />
      </PrivateRoute>
      <PrivateRoute path='/consultar-cupo'>
        <ConsultarCupo />
      </PrivateRoute>
      <PrivateRoute path='/previsualizar'>
        <DocumentoPreview />
      </PrivateRoute>
      <PrivateRoute path='/validar-contacto'>
        <ValidarContacto />
      </PrivateRoute>
      <PrivateRoute path='/ayuda'>
        <Ayuda />
      </PrivateRoute>
      <PrivateRoute path='/verificar-contacto/:id/:contacto'>
        <ConfirmarContactoView />
      </PrivateRoute>
    </Switch>
  </Box>
);

export default Pages;
