import { TextField } from '@mui/material';

const DateTextInput = ({
  fieldName,
  label,
  register,
  errors,
  variant,
  required,
  value,
  change,
  validations = {},
  errorStyle = {},
  disabled,
  error,
}) => {
  return (
    <div>
      <TextField
        variant={variant}
        fullWidth
        name={fieldName}
        label={label}
        type='date'
        defaultValue={value}
        id={fieldName}
        disabled={disabled}
        onChange={(newValue) => {}}
        InputLabelProps={{
          shrink: true,
        }}
        {...register(fieldName, {
          required: {
            value: required,
            message: 'Debe ingresar ' + label,
          },
          validate: { ...validations },
          onChange: (e) => change(e.target.value),
        })}
        error={!!error}
        helperText={error ? error.message : ''}
      />
      {errors[fieldName] && (
        <p style={{ color: 'red', ...errorStyle }}>{errors[fieldName].message}</p>
      )}
    </div>
  );
};
DateTextInput.defaultProps = {
  required: true,
  variant: 'standard',
};
export default DateTextInput;
