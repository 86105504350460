import { axiosGet } from '../ServiceBase.js';

export const findPrescripcionesElectronicas = async (data) => {
  let query = [];

  if (data.idAfiliado) {
    query.push(`idAfiliado=${data.idAfiliado}`);
  }
  if (data.documento) {
    query.push(`documento=${data.documento}`);
  }
  if (data.fechaDesde) {
    query.push(`fechaDesde=${data.fechaDesde}`);
  }
  if (data.fechaHasta) {
    query.push(`fechaHasta=${data.fechaHasta}`);
  }
  if (data.limit) {
    query.push(`limit=${data.limit}`);
  }

  const queryParams = query?.length > 0 ? `?${query.join('&')}` : '';
  const recetas = await axiosGet(`/v1/prescripciones-electronicas${queryParams}`);
  return recetas;
};
