import { axiosGet, axiosPost, axiosPostFile } from '../ServiceBase.js';

/**
 * Generar una prescripcionlelectronica
 * @returns
 */
export const generarPrescripcionElectronica = async (data) => {
  return axiosPost('/v1/prescripciones-electronicas', data);
};

/**
 * Generar recodificacion de una prescripcionlelectronica
 * @returns
 */
export const generarRecodificacionPrescripcionElectronica = async (data) => {
  return axiosPost('/v1/prescripciones-electronicas/recodificacion', data);
};

/**
 *
 * @param {idPrescripcionElectronica, observacion} data
 * @returns
 */
export const anularPrescripcionElectronica = async (data) => {
  const prescripcions = await axiosPost('/v1/prescripciones-electronicas/anulacion', data);
  return prescripcions;
};

/**
 *
 * Generar una prescripcionlelectronica
 * @returns
 */
export const getReportePrescripcionElectronica = async (data) => {
  return axiosPostFile('/v1/prescripciones-electronicas/reporte', data);
};

/**
 * Enviar por correo la prescripcion electronica
 * @returns
 */
export const enviarPrescripcionElectronica = async (data) => {
  return axiosPostFile('/v1/prescripciones-electronicas/email', data);
};

/**
 * Enviar por correo la prescripcion electronica
 * @returns
 */
export const findPrescripcionElectronica = async (idPrescripcionElectronica) => {
  return await axiosGet(`/v1/prescripciones-electronicas/${idPrescripcionElectronica}`);
};
