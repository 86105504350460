import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { Box, Button, CardActions, FormControl, Grid, Stack } from '@mui/material';
import { Assignment, AttachMoney, HighlightOff } from '@mui/icons-material';
import LoadingButton from '../../@iosper/components/LoadingButton';
import CustomizedSnackbars from '../Notifications/SnackBar';
import BuscarAfiliado from '../Afiliado/BuscarAfiliado';
import { PracticasOrdenConsultaAutocomplete } from '../Practicas/PracticasOrdenConsultaAutocomplete';
import { getValidarOrden, getVerificarCostoOrden } from '../../services/Seo/Orden';
import OrdenPreview from './OrdenPreview';
import OrdenValidadaResumidaForm from './OrdenValidadaResumidaForm';
import VerificarCostoOrdenResumidaView from './VerificarCostoOrdenResumidaView';
import { UltimosPrestadoresOrden } from '../Prestador/UltimosPrestadoresOrden';
import { getPrestadorLogueado } from '../../services/Busquedas/Prestadores';
import { getConsultorioLogueado } from '../../services/Busquedas/Prestadores';
import PrestadorSelect from '../Prestador/PrestadorSelect';
import PrestadorAutocompleteVirtualizado from '../Prestador/PrestadorAutocompleteVirtualizado';

const EmitirOrdenConsulta = () => {
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [prestadorLogueado, setPrestadorLogueado] = useState(null);
  const [consultorioLogueado, setConsultorioLogueado] = useState(null);

  // Dialog Orden
  const [open, setOpen] = useState(false);
  const [datosOrdenReceived, setDatosOrdenReceived] = useState({});
  const [loadingOrden, setLoadingOrden] = useState(false);

  // Dialog Orden Preview
  const [openDialogReporte, setOpenDialogReporte] = useState(false);
  const [urlReporte, setUrlReporte] = useState('');
  const [datosOrdenGenerada, setDatosOrdenGenerada] = useState('');

  /**Definimos los estados de las variables seleccionadas, las cuales seran usadas por el service */
  const [selectedAfiliado, setSelectedAfiliado] = useState(null);
  const [selectedPractica, setSelectedPractica] = useState(null);
  const [selectedPrestador, setSelectedPrestador] = useState(null);
  const [keyComponentAfiliado, setKeyComponentAfiliado] = useState(0);

  // Dialog Verificar Costo Orden
  const [openVerificarCostoOrden, setOpenVerificarCostoOrden] = useState(false);
  const [datosVerificarCostoOrdenReceived, setDatosVerificarCostoOrdenReceived] = useState({});
  const [loadingVerificarCostoOrden, setLoadingVerificarCostoOrden] = useState(false);

  const onSelectAfiliado = (afiliado) => {
    setSelectedAfiliado(afiliado);
  };

  useEffect(() => {
    const getPrestador = async () => {
      const prestador = await getPrestadorLogueado();
      if (prestador != null) {
        setPrestadorLogueado(prestador);
        setSelectedPrestador(prestador);
      } else {
        const consultorio = await getConsultorioLogueado();
        if (consultorio != null) {
          setConsultorioLogueado(consultorio);
        }
      }
    };

    if (prestadorLogueado === null && consultorioLogueado === null) {
      getPrestador();
    }
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const handlerLimpiar = () => {
    reset();
    onSelectAfiliado(null);
    setKeyComponentAfiliado(1 + Math.random() * (1000 - 1));
    setSelectedAfiliado(null);
    setSelectedPractica(null);
    setSelectedPrestador(null);
  };

  const getDataOrden = () => {
    let data = {
      numeroTarjeta: selectedAfiliado.numeroTarjeta,
      codigoPrestador: selectedPrestador.matricula,
      tipoPrestador: selectedPrestador.tipo,
      codigoPrescriptor: 0,
      practicas: [{ codigo: selectedPractica.codigo, cantidad: 1 }],
    };
    return data;
  };

  const onSubmitOrden = async (data, event) => {
    setLoadingOrden(true);
    event.preventDefault();

    try {
      const res = await getValidarOrden(getDataOrden());
      setDatosOrdenReceived(res);
      setOpen(!open);
      setLoadingOrden(false);
    } catch (e) {
      setLoadingOrden(false);
    }
  };

  const validatePractica = () => {
    return selectedPractica == null ? false : true;
  };

  const validateAfiliado = () => {
    return selectedAfiliado !== null ? true : false;
  };

  const handlerSelectPractica = (practica) => {
    setSelectedPractica(practica);
  };

  const handlerOrdenConfirmada = (datosOrden, urlPdf) => {
    setSuccess('Orden generada correctamente');
    setUrlReporte(urlPdf);
    setDatosOrdenGenerada(datosOrden);
    setOpenDialogReporte(true);
  };
  const handlerVerificarCosto = async () => {
    setLoadingVerificarCostoOrden(true);
    try {
      const res = await getVerificarCostoOrden(getDataOrden());
      setDatosVerificarCostoOrdenReceived(res);
      setOpenVerificarCostoOrden(!openVerificarCostoOrden);
      setLoadingVerificarCostoOrden(false);
    } catch (e) {
      setLoadingVerificarCostoOrden(false);
    }
  };

  const handlerCerrarPreview = () => {
    setOpenDialogReporte(false);
    handlerLimpiar();
  };

  const validatePrestador = () => {
    // Si no esta activo seguimos mostrando que debe seleccionar un prestador
    return selectedPrestador !== null && selectedPrestador.codigoEstado === '1' ? true : false;
  };

  const handlerSelectPrestador = (prestador) => {
    setSelectedPrestador(prestador);
  };

  const handlerUltimoPrestador = (prestador) => {
    handlerSelectPrestador(prestador);
  };

  // Determinar si hay errores
  const hasErrors = Object.keys(errors).length > 0;

  const DrawerForm = (
    <form onSubmit={handleSubmit(onSubmitOrden)}>
      <CardContent sx={{ p: 2 }}>
        <FormControl fullWidth variant='outlined' sx={{ marginBottom: 3 }}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={prestadorLogueado?.idProfesional ? 12 : 11}>
              {prestadorLogueado?.idProfesional || consultorioLogueado?.idConsultorio ? (
                <PrestadorSelect
                  key={keyComponentAfiliado + '_prestador'}
                  value={selectedPrestador}
                  disabled={prestadorLogueado !== null ?? true}
                  name='prestadorRegister'
                  {...register('prestadorRegister', {
                    validate: () => validatePrestador,
                  })}
                  onSelectPrestador={handlerSelectPrestador}
                />
              ) : (
                <PrestadorAutocompleteVirtualizado
                  key={keyComponentAfiliado + '_prestador'}
                  value={selectedPrestador}
                  disabled={prestadorLogueado !== null ?? true}
                  name='prestadorRegister'
                  {...register('prestadorRegister', {
                    validate: () => selectedPrestador?.idPrestador || false,
                  })}
                  onSelectedPrestador={handlerSelectPrestador}
                />
              )}
            </Grid>
            {!prestadorLogueado?.idProfesional && (
              <Grid item xs={1}>
                <UltimosPrestadoresOrden onSelectEvent={handlerUltimoPrestador} />
              </Grid>
            )}
          </Grid>
          {errors.prestadorRegister && errors.prestadorRegister.type === 'validate' && (
            <p style={{ color: 'red' }}>Debe seleccionar el prestador</p>
          )}
        </FormControl>

        <FormControl fullWidth={true} variant='outlined' sx={{ marginTop: 1 }}>
          <BuscarAfiliado
            initialValue={selectedAfiliado}
            onSelectAfiliado={(afiliado) => setSelectedAfiliado(afiliado)}
            ref={register('afiliadoRegister', {
              validate: validateAfiliado,
            })}
          />
          {errors.afiliadoRegister != null && (
            <p style={{ color: 'red' }}>Debe seleccionar un afiliado activo</p>
          )}
        </FormControl>
        <FormControl fullWidth={true} variant='outlined' sx={{ marginTop: 1 }}>
          <PracticasOrdenConsultaAutocomplete
            value={selectedPractica}
            onChangeEvent={handlerSelectPractica}
            {...register('practicaRegister', {
              validate: validatePractica,
            })}
          />
          {errors.practicaRegister != null && (
            <p style={{ color: 'red' }}>Debe seleccionar la práctica</p>
          )}
        </FormControl>
      </CardContent>

      <CardActions sx={{ justifyContent: 'flex-end', p: 2, fullWidth: true }}>
        <Box sx={{ width: '100%' }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
          >
            <Button
              variant='contained'
              startIcon={<HighlightOff />}
              color='neutral'
              onClick={handlerLimpiar}
            >
              {' '}
              Limpiar{' '}
            </Button>
            <LoadingButton
              fullWidth={true}
              variant='contained'
              color='neutral'
              startIcon={<AttachMoney />}
              loading={loadingVerificarCostoOrden ? 'show' : 'hide'}
              content={'Verificar Costo'}
              onClick={handleSubmit(handlerVerificarCosto)}
            />
            <LoadingButton
              fullWidth={true}
              variant='contained'
              size='medium'
              color='primary'
              startIcon={<Assignment />}
              type='submit'
              loading={loadingOrden ? 'show' : 'hide'}
              content={'Generar'}
              disabled={
                !selectedAfiliado?.idAfiliado ||
                !selectedPractica?.idPractica ||
                !selectedPrestador?.idPrestador ||
                hasErrors
              }
            />
          </Stack>
        </Box>
      </CardActions>
    </form>
  );

  const handleBorrarMensaje = () => {
    setError('');
    setSuccess('');
    setWarning('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5'>
              Nueva Orden de Consulta Impresa
            </Typography>
            <Typography gutterBottom>
              Acá podrás emitir órdenes de consulta, para luego descargarlas, imprimirlas y/o
              enviarlas por correo electrónico.
            </Typography>
          </CardContent>

          {DrawerForm}
        </Card>

        <CustomizedSnackbars
          open={success}
          autoHideDuration={8000}
          severity='success'
          message={success}
          onDeleteMessage={handleBorrarMensaje}
        />
        <CustomizedSnackbars
          open={error}
          autoHideDuration={8000}
          severity='error'
          message={error}
          onDeleteMessage={handleBorrarMensaje}
        />
        <CustomizedSnackbars
          open={warning}
          autoHideDuration={8000}
          severity='warning'
          message={warning}
          onDeleteMessage={handleBorrarMensaje}
        />
      </Grid>

      <OrdenValidadaResumidaForm
        open={open}
        setOpen={setOpen}
        onClose={() => setOpen(false)}
        datosOrden={datosOrdenReceived}
        handlerOrdenConfirmada={handlerOrdenConfirmada}
      />

      <VerificarCostoOrdenResumidaView
        open={openVerificarCostoOrden}
        setOpen={setOpenVerificarCostoOrden}
        onClose={() => setOpenVerificarCostoOrden(false)}
        datosOrden={datosVerificarCostoOrdenReceived}
      />

      <OrdenPreview
        open={openDialogReporte}
        setOpen={setOpenDialogReporte}
        urlReporte={urlReporte}
        datosOrden={datosOrdenGenerada}
        showMail={true}
        nombreReporte={'Orden_iosper_' + datosOrdenGenerada.numeroOrden}
        handlerCerrarPreview={handlerCerrarPreview}
      ></OrdenPreview>
    </Grid>
  );
};

export default EmitirOrdenConsulta;
