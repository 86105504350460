import { axiosGet, axiosPost } from '../ServiceBase.js';

export const findDiagnosticosByDescripcion = async (data) => {
  const diagnosticos = await axiosPost('/v1/busquedas/diagnosticos/filter', {
    diagnostico: data || '',
  });
  return diagnosticos;
};

export const getUltimosDiagnosticos = async (dataQuery) => {
  const diagnosticos = await axiosGet(`/v1/busquedas/diagnosticos/get-latest${dataQuery || ''}`);
  return diagnosticos;
};

export const getUltimosDiagnosticosPrescripcionElectronica = async (dataQuery) => {
  const diagnosticos = await axiosGet(
    `/v1/prescripciones-electronicas/diagnosticos/ultimos${dataQuery || ''}`,
  );
  return diagnosticos;
};
