import HighlightOff from '@mui/icons-material/HighlightOff';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import QrPreview from '../Common/QrPreview';
import DetalleCoberturaOrdenData from './components/DetalleCoberturaOrdenData';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState } from 'react';
import { enviarOrden } from '../../services/Seo/Orden';
import { PreviewBox } from '../Common/PreviewBox';
import { PreviewField } from '../Common/PreviewField';

const OrdenPreview = ({ showSuccessIcon = false, ...props }) => {
  const { setOpen, handlerCerrarPreview } = props;
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [successCorreo, setSuccessCorreo] = useState('');
  const [practicasSinCupo, setPracticasSinCupo] = useState([]);

  const handleClose = () => {
    if (setOpen) {
      setOpen(false);
    }
    if (handlerCerrarPreview) {
      handlerCerrarPreview();
    }
  };

  const handlerEnviarCorreo = async (correo) => {
    setLoadingEnviar(true);
    setSuccessCorreo('');
    try {
      const res = await enviarOrden({
        numeroOrden: props.datosOrden.orden.numeroOrdenSinFormato,
        correo: correo,
      });

      setSuccessCorreo('Orden enviada al correo: ' + correo);
      setLoadingEnviar(false);
    } catch (e) {
      setLoadingEnviar(false);
    }
  };

  useEffect(() => {
    if (props?.datosOrden != undefined && props?.datosOrden != '') {
      setPracticasSinCupo(
        props?.datosOrden?.practicas
          .filter(
            (p) =>
              p.cuposDisponible != null &&
              Object.values(p.cuposDisponible).filter((c) => c == false).length > 0,
          )
          .map((p) => p.codigoPractica),
      );
    }
  }, [props?.datosOrden]);

  return (
    <div>
      <Dialog
        maxWidth='lg'
        open={props.open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='felx-end'
            spacing={1}
          >
            <Grid item>
              <Typography variant='h6'>Orden Generada</Typography>
            </Grid>
            <Grid item>
              <CheckCircleIcon fontSize='small' color='primary'></CheckCircleIcon>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {props.datosOrden.orden && (
                <QrPreview
                  showDownload={props.showDownload}
                  showPrint={props.showPrint}
                  urlReporte={props.urlReporte}
                  showMail={props.showMail}
                  correo={props.datosOrden.afiliado.correo}
                  loadingEnviar={loadingEnviar}
                  successCorreo={successCorreo}
                  onEnviarCorreo={handlerEnviarCorreo}
                  nombreReporte={'Orden_iosper_' + props.datosOrden.orden.numeroOrden}
                  qrValue={JSON.stringify({
                    codigo: props.datosOrden.orden.numeroOrdenFormatoCodigo,
                  })}
                  showSuccessIcon={showSuccessIcon}
                />
              )}
              <PreviewBox>
                {props.datosOrden.orden && (
                  <Grid item xs={12}>
                    <PreviewField
                      id='numero-orden'
                      label='Nro. de Orden'
                      value={props.datosOrden.orden.numeroOrden}
                    />
                  </Grid>
                )}
                {props.datosOrden.afiliado && (
                  <Grid item xs={12}>
                    <PreviewField
                      id='afiliado'
                      label='Afiliado'
                      value={`(${props.datosOrden.afiliado.numerodocumentoformateado}) ${props.datosOrden.afiliado.nombre}, ${props.datosOrden.afiliado.apellido}`}
                    />
                  </Grid>
                )}
                {props.datosOrden.prestador && (
                  <Grid item xs={12}>
                    <PreviewField
                      id='prestador'
                      label='Prestador'
                      value={`(${props.datosOrden.prestador.codigoPrestador}) ${
                        props.datosOrden.prestador.razonSocial
                      } ${
                        props.datosOrden.prestador?.especialidades
                          ? `(${props.datosOrden.prestador?.especialidades
                              ?.map((espe) => espe.descripcion)
                              .join(', ')} ) `
                          : ''
                      } `}
                    />
                  </Grid>
                )}
                {props.datosOrden.prescriptor?.idPrestador != null && (
                  <Grid item xs={12}>
                    <PreviewField
                      id='prescriptor'
                      label='Prescriptor'
                      value={`(${props.datosOrden.prescriptor.codigoPrestador}) ${
                        props.datosOrden.prescriptor.razonSocial
                      } ${
                        props.datosOrden.prescriptor?.especialidades
                          ? `(${props.datosOrden.prescriptor?.especialidades
                              ?.map((espe) => espe.descripcion)
                              .join(', ')} ) `
                          : ''
                      } `}
                    />
                  </Grid>
                )}
                {props.datosOrden.arancelTotal && (
                  <Grid item xs={12}>
                    <PreviewField
                      id='coseguro'
                      label='Coseguro a pagar al prestador'
                      value={`$ ${props.datosOrden.arancelTotal.totalNumero}`}
                    />
                  </Grid>
                )}
                {props.datosOrden.practicas && (
                  <Grid item xs={12}>
                    <DetalleCoberturaOrdenData
                      detallePracticas={props.datosOrden.practicas}
                      nuevaVistaPreview={true}
                    />
                  </Grid>
                )}
                {practicasSinCupo?.length > 0 && (
                  <Grid item xs={12}>
                    <PreviewField
                      id='practicas-sin-cupo'
                      label='Practicas sin cupo para la próxima orden'
                      value={practicasSinCupo?.toString()}
                    />
                  </Grid>
                )}
              </PreviewBox>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            size='small'
            startIcon={<HighlightOff />}
            variant='contained'
            color='primary'
            onClick={(e) => handleClose(e)}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrdenPreview;
