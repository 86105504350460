import { axiosGet } from '../ServiceBase.js';

export const getGruposPlanes = async (idAfiliado = 0) => {
  try {
    const gruposPlanes = await axiosGet(`/v1/busquedas/gruposplanes`);
    return gruposPlanes;
  } catch (err) {
    return Promise.reject(err);
  }
};
