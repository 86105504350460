import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getGruposPlanes } from '../../services/Busquedas/GruposPlanes';
import { forwardRef, useEffect, useState } from 'react';

const GruposPlanesCombo = forwardRef(
  ({ idGrupoPlan = null, value, onSelectPlanEspecial, disabled, ...props }, ref) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [helperText, setHelperText] = useState(null);

    useEffect(() => {
      setOptions([]);
      setHelperText(null);

      setLoading(true);
      const getGruposPlanesFetch = async () => {
        const gruposPlanes = await getGruposPlanes();
        if (!gruposPlanes || gruposPlanes.length <= 0) {
          setHelperText('No existen planes para asociar');
        }
        setOptions(gruposPlanes);

        // Preseleccionar el plan si se proporcionó idPlanEspecial y no hay un valor seleccionado
        if (idGrupoPlan) {
          const planPreseleccionado = gruposPlanes.find((plan) => plan.idGrupoPlan === idGrupoPlan);
          if (planPreseleccionado) {
            handleChange(planPreseleccionado);
          }
        }
        setLoading(false);
      };

      try {
        getGruposPlanesFetch();
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }, [idGrupoPlan]);

    const handleChange = (newValue) => {
      if (onSelectPlanEspecial) {
        onSelectPlanEspecial(newValue);
      }
    };

    return (
      <Autocomplete
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option?.idGrupoPlan === value.idGrupoPlan}
        getOptionLabel={(a) => `${a.observacion}`}
        onChange={(_, newValue) => handleChange(newValue)}
        value={value || null}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Plan Afiliado'
            variant='standard'
            color={helperText && 'warning'}
            helperText={helperText || ''}
            FormHelperTextProps={{
              sx: {
                color: 'warning.main',
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color='inherit' size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  },
);

export default GruposPlanesCombo;
