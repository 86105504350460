import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Input,
  Alert,
  TextField,
  Pagination,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  DoDisturbOn as DoDisturbOnIcon,
  AddCircle as AddCircleIcon,
  Search as SearchIcon,
  CloseSharp as CloseSharpIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { TreeView, TreeItem, treeItemClasses } from '@mui/lab';
import { styled, alpha } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import { findDiagnosticosByDescripcion } from '../../services/Busquedas/Diagnosticos';
import { useWidth } from '../../@iosper/utils/UseWidth';
import CustomizedSnackbars from '../Notifications/SnackBar';
import LoadingButton from '../../@iosper/components/LoadingButton';
import ModalBase from '../Controls/ModalBase';

const ITEMS_PER_PAGE = 10;
const MAX_RESULTS = 50;
const MIN_SEARCH_LENGTH = 3;
const MAX_SEARCH_LENGTH = 50;

const useStyles = makeStyles({
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: '36px',
    zIndex: 1,
    cursor: 'pointer',
  },
});

const CustomTreeItem = styled((props) => (
  <TreeItem {...props} onDoubleClick={(event) => props.onDoubleClick(event, props.nodeId)} />
))(({ theme }) => ({
  color: theme.palette.grey[800],
  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: '0.9rem',
      fontWeight: 400,
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .MuiSvgIcon-root': {
      fontSize: 24,
    },
  },
  [`& .${treeItemClasses.iconContainer} .MuiSvgIcon-root.MuiTreeItem-expandIcon`]: {
    color: theme.palette.primary.main,
  },
  [`& .${treeItemClasses.iconContainer} .MuiSvgIcon-root.MuiTreeItem-collapseIcon`]: {
    color: grey[500],
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const getDiagnosticoNombre = (diagnostico) => {
  if (!diagnostico) return;
  return `(${diagnostico?.codigo}) ${diagnostico?.descripcion}`;
};

const DiagnosticoSelector = React.forwardRef(
  (
    {
      onSelectedDiagnostico,
      errorSelector,
      diagnostico: initialDiagnostico,
      label = 'Buscá el Diagnóstico',
      disabled,
    },
    ref,
  ) => {
    const classes = useStyles();
    const screenWidth = useWidth();
    const [diagnosticos, setDiagnosticos] = useState([]);
    const [disabledSearchMedicamento, setDisabledSearchMedicamento] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [alertResultFind, setAlertResultFind] = useState('');
    const [diagnostico, setDiagnostico] = useState(initialDiagnostico || null);
    const [visibleFind, setVisibleFind] = useState(true);
    const [visibleInfo, setVisibleInfo] = useState(false);
    const [textFieldMedicamento, setTextFieldMedicamento] = useState(label);
    const [error, setError] = useState('');
    const [expanded, setExpanded] = useState([]);
    const [selectedNode, setSelectedNode] = useState(null);
    const [page, setPage] = useState(1);
    const [isFullyExpanded, setIsFullyExpanded] = useState(false);

    const {
      register,
      reset,
      handleSubmit,
      formState: { errors },
    } = useForm({ mode: 'onBlur' });

    const handleClose = useCallback(() => {
      setOpen(false);
    }, []);

    const openCloseModal = useCallback(() => {
      if (!open) {
        clean();
      }
      setOpen(!open);
    }, [open]);

    const clean = useCallback(() => {
      setDiagnosticos([]);
      setDiagnostico(null);
      setVisibleFind(true);
      reset();
      setVisibleInfo(false);
      setExpanded([]);
      setPage(1);
      setIsFullyExpanded(false);
    }, [reset]);

    const buildTree = useCallback((items) => {
      const itemMap = {};
      const roots = [];

      items.forEach((item) => {
        itemMap[item.codigo] = { ...item, children: [] };
      });

      items.forEach((item) => {
        if (item.padre && itemMap[item.padre]) {
          itemMap[item.padre].children.push(itemMap[item.codigo]);
        } else {
          roots.push(itemMap[item.codigo]);
        }
      });

      return roots;
    }, []);

    const findNodeById = useCallback((nodes, id) => {
      for (let node of nodes) {
        if (node.codigo === id) {
          return node;
        }
        if (node.children) {
          const found = findNodeById(node.children, id);
          if (found) return found;
        }
      }
      return null;
    }, []);

    const handleTreeItemSelect = useCallback(
      (event, nodeId) => {
        event.stopPropagation();
        const selectedDiagnostico = findNodeById(diagnosticos, nodeId);
        if (selectedDiagnostico) {
          setSelectedNode(selectedDiagnostico);
        }
      },
      [diagnosticos, findNodeById],
    );

    const handleDoubleClick = useCallback(
      (event, nodeId) => {
        event.stopPropagation();
        const selectedDiagnostico = findNodeById(diagnosticos, nodeId);
        if (selectedDiagnostico) {
          setSelectedNode(selectedDiagnostico);
          handleSelect();
        }
      },
      [diagnosticos, findNodeById],
    );

    const renderTree = useCallback(
      (nodes) => (
        <CustomTreeItem
          key={nodes.codigo}
          nodeId={nodes.codigo}
          label={`(${nodes.codigo}) ${nodes.descripcion}`}
          onClick={(event) => handleTreeItemSelect(event, nodes.codigo)}
          onDoubleClick={(event) => handleDoubleClick(event, nodes.codigo)}
        >
          {Array.isArray(nodes.children) && nodes.children.length > 0
            ? nodes.children.map((node) => renderTree(node))
            : null}
        </CustomTreeItem>
      ),
      [handleDoubleClick, handleTreeItemSelect],
    );

    const find = useCallback(
      async (data, e) => {
        e.preventDefault();
        setLoading(true);
        setVisibleInfo(false);
        setAlertResultFind('');
        setError('');
        setPage(1);
        setIsFullyExpanded(false);

        if (data.nombre === '') {
          setError('Debes ingresar un diagnóstico para realizar tu búsqueda.');
          setLoading(false);
        } else {
          try {
            const result = await findDiagnosticosByDescripcion(data.nombre);
            if (result && result.length > 0) {
              if (result.length >= MAX_RESULTS) {
                setVisibleInfo(true);
              }
              const treeData = buildTree(result);
              setDiagnosticos(treeData);

              const expandedIds = expandTreeNodes(treeData);
              setExpanded(expandedIds);

              setVisibleFind(false);
              setPage(1);
            } else {
              setAlertResultFind('No se han encontrado Diagnósticos.');
              clean();
            }
          } catch (error) {
            console.error('Error al buscar diagnósticos:', error);
            setAlertResultFind('Ocurrió un error al buscar diagnósticos.');
          } finally {
            setLoading(false);
          }
        }
      },
      [buildTree, clean],
    );

    const expandTreeNodes = useCallback((nodes) => {
      const expandedIds = [];
      let totalNodes = 0;

      const countNodes = (node) => {
        totalNodes++;
        if (node.children) {
          node.children.forEach(countNodes);
        }
      };

      const expandNode = (node) => {
        expandedIds.push(node.codigo);
        if (node.children) {
          node.children.forEach(expandNode);
        }
      };

      nodes.forEach(countNodes);

      if (totalNodes < 15) {
        nodes.forEach(expandNode);
      } else {
        const expandFirstParentLevels = (node, currentLevel = 1, maxLevel = 3) => {
          if (currentLevel > maxLevel) return;

          expandedIds.push(node.codigo);

          if (node.children && node.children.length > 0) {
            node.children.forEach((child) =>
              expandFirstParentLevels(child, currentLevel + 1, maxLevel),
            );
          }
        };

        if (nodes.length > 0) {
          expandFirstParentLevels(nodes[0]);
        }
      }

      return expandedIds;
    }, []);

    const handleSelect = useCallback(() => {
      if (selectedNode) {
        setDiagnostico(selectedNode);
        setDisabledSearchMedicamento(true);
        openCloseModal();
        reset();
        setTextFieldMedicamento('Diagnóstico');
        setVisibleInfo(false);
      }
    }, [selectedNode, openCloseModal, reset]);

    const handleSearchAnother = useCallback(() => {
      setVisibleFind(true);
      setSelectedNode(null);
    }, []);

    const handleIconClose = useCallback((e = null) => {
      e?.preventDefault();
      cleanLableAndIcon();
      setDiagnostico(null);
    }, []);

    const cleanLableAndIcon = useCallback(() => {
      setDisabledSearchMedicamento(false);
      setTextFieldMedicamento(label);
    }, [label]);

    const handleOnClickIconSearch = useCallback(
      (e) => {
        e.preventDefault();
        if (!disabled) {
          openCloseModal();
        }
      },
      [openCloseModal, disabled],
    );

    const handleClickMedicamento = useCallback(
      (e) => {
        if (!disabledSearchMedicamento) {
          if (!disabled) {
            openCloseModal();
          }
        }
      },
      [disabledSearchMedicamento, openCloseModal, disabled],
    );

    const handleBorrarMensaje = useCallback(() => {
      setAlertResultFind('');
      setError('');
    }, []);

    const toggleExpansion = useCallback(() => {
      if (isFullyExpanded) {
        setExpanded([]);
        setIsFullyExpanded(false);
      } else {
        const allNodeIds = getAllNodeIds(diagnosticos);
        setExpanded(allNodeIds);
        setIsFullyExpanded(true);
      }
    }, [isFullyExpanded, diagnosticos]);

    const getAllNodeIds = useCallback((nodes) => {
      let ids = [];
      nodes.forEach((node) => {
        ids.push(node.codigo);
        if (node.children) {
          ids = [...ids, ...getAllNodeIds(node.children)];
        }
      });
      return ids;
    }, []);

    const paginatedDiagnosticos = useMemo(
      () => diagnosticos.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE),
      [diagnosticos, page],
    );

    const handleChangePage = useCallback((event, value) => {
      setPage(value);
    }, []);

    useEffect(() => {
      setDiagnostico(initialDiagnostico || null);
    }, [initialDiagnostico]);

    useEffect(() => {
      onSelectedDiagnostico(diagnostico);
      cleanLableAndIcon();
      setDisabledSearchMedicamento(!!diagnostico);
    }, [diagnostico]);

    const handleToggle = useCallback((event, nodeIds) => {
      setExpanded(nodeIds);
    }, []);

    const handleInputChange = useCallback((event) => {
      const value = event.target.value;
      if (value.length <= MAX_SEARCH_LENGTH) {
        event.target.value = value;
      } else {
        event.target.value = value.slice(0, MAX_SEARCH_LENGTH);
      }
    }, []);

    const diagnosticoTree = (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '60vh',
          bgcolor: 'background.paper',
        }}
      >
        <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
          <TreeView
            aria-label='diagnósticos'
            defaultCollapseIcon={<DoDisturbOnIcon className='MuiTreeItem-collapseIcon' />}
            defaultExpandIcon={<AddCircleIcon className='MuiTreeItem-expandIcon' />}
            expanded={expanded}
            onNodeToggle={handleToggle}
            onNodeSelect={handleTreeItemSelect}
          >
            {paginatedDiagnosticos.map((item) => renderTree(item))}
          </TreeView>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Box>
            <Button
              onClick={toggleExpansion}
              variant='outlined'
              startIcon={isFullyExpanded ? <ChevronRightIcon /> : <ExpandMoreIcon />}
            >
              {isFullyExpanded ? 'Contraer' : 'Expandir'}
            </Button>
          </Box>
          <Pagination
            count={Math.ceil(diagnosticos.length / ITEMS_PER_PAGE)}
            page={page}
            onChange={handleChangePage}
          />
        </Box>
        {visibleInfo && screenWidth !== 'xs' && (
          <Grid item sx={{ mt: 2 }}>
            <Alert severity='info'>
              Sólo se muestran los primeros {MAX_RESULTS} resultados, agregue más criterios de
              búsqueda.
            </Alert>
          </Grid>
        )}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1, p: 0 }}>
          <Button
            variant='contained'
            color='neutral'
            startIcon={<SearchIcon />}
            onClick={handleSearchAnother}
          >
            Buscar otro
          </Button>
          <Button
            variant='contained'
            color='primary'
            startIcon={<CheckCircleIcon />}
            onClick={handleSelect}
            disabled={!selectedNode}
          >
            Seleccionar
          </Button>
        </Box>
      </Box>
    );
    const formFind = (
      <form>
        <FormControl fullWidth={true} variant='outlined'>
          <TextField
            variant='standard'
            type='text'
            fullWidth
            margin='normal'
            name='nombre'
            label='Ingresá un nombre o código del Diagnóstico'
            inputProps={{
              maxLength: MAX_SEARCH_LENGTH,
            }}
            onChange={handleInputChange}
            {...register('nombre', {
              maxLength: {
                value: MAX_SEARCH_LENGTH,
                message: 'El campo es demasiado largo',
              },
              minLength: {
                value: MIN_SEARCH_LENGTH,
                message: `El campo debe tener como mínimo ${MIN_SEARCH_LENGTH} caracteres.`,
              },
            })}
          />
          {errors['nombre'] && <p style={{ color: 'red' }}>{errors['nombre'].message}</p>}
        </FormControl>
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <LoadingButton
              fullWidth={true}
              type='submit'
              variant='contained'
              disabled={disabled}
              color='primary'
              loading={loading ? 'show' : 'hide'}
              content={'Buscar'}
              startIcon={<SearchIcon />}
              onClick={handleSubmit(find)}
            />
          </Grid>
        </Grid>
      </form>
    );

    const iconAdornment = (
      <IconButton
        variant='outlined'
        aria-label='Buscar Diagnóstico'
        disabled={disabled}
        onClick={disabledSearchMedicamento ? handleIconClose : handleOnClickIconSearch}
        size='large'
        edge='end'
      >
        {disabledSearchMedicamento ? <CloseSharpIcon /> : <SearchIcon />}
      </IconButton>
    );

    return (
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{ position: 'relative' }}>
              <FormControl fullWidth variant='standard'>
                <InputLabel
                  htmlFor='outlined-adornment-Diagnostico'
                  disabled={disabled}
                  sx={{ width: 'calc(100% - 34px)' }}
                >
                  {textFieldMedicamento}
                </InputLabel>
                <Input
                  id='outlined-adornment-Diagnostico'
                  name='Diagnóstico'
                  multiline
                  maxRows={4}
                  label='Buscá el Diagnóstico'
                  aria-describedby='outlined-Diagnostico-helper-text'
                  value={diagnostico ? getDiagnosticoNombre(diagnostico) : ''}
                  onClick={handleClickMedicamento}
                  onBlur={handleClickMedicamento}
                  disabled={disabled}
                  endAdornment={<InputAdornment position='end'>{iconAdornment}</InputAdornment>}
                />
              </FormControl>
              <div className={classes.overlay} onClick={handleClickMedicamento} />
            </div>
            {errorSelector && screenWidth === 'xs' && (
              <p style={{ color: 'red' }}>{errorSelector}</p>
            )}
          </Grid>
        </Grid>

        <ModalBase
          fullWidth={true}
          open={open}
          content={visibleFind ? formFind : diagnosticoTree}
          title='Buscar Diagnóstico'
          onClose={handleClose}
        />
        {alertResultFind && (
          <CustomizedSnackbars
            open={Boolean(alertResultFind)}
            severity='info'
            message={alertResultFind}
            onDeleteMessage={handleBorrarMensaje}
          />
        )}
        {error && (
          <CustomizedSnackbars
            open={Boolean(error)}
            severity='error'
            message={error}
            onDeleteMessage={handleBorrarMensaje}
          />
        )}
      </Box>
    );
  },
);

DiagnosticoSelector.propTypes = {
  onSelectedDiagnostico: PropTypes.func,
  errorSelector: PropTypes.string,
  diagnostico: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default React.memo(DiagnosticoSelector);
